import React, {useContext, useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useDispatch, useSelector} from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {LocalizationContext} from '../locales/Translate';
import {TextField} from '@material-ui/core';
import {createItem, updateItem} from '../store/actions/shoppingList';
import {logCurrentScreen} from '../store/actions/analytics';

const useStyles = makeStyles((theme) => ({
  modal: {},
}));

export default props =>  {
  const {listId, item, handleClose, open} = props;
  const classes = useStyles();
  const {locale} = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const [itemName, setItemName] = useState(item && item.name ? item.name : '');
  const [quantity, setQuantity] = useState(item && item.quantity ? item.quantity : '');
  const user = useSelector(state => state.user);

  const handleCancel = () => {
    handleClose();
    setItemName(item && item.name ? item.name : '');
    setQuantity(item && item.quantity ? item.quantity : '');
  }

  useEffect(() => {
    dispatch(logCurrentScreen('ItemModal'));
  }, [dispatch]);

  return (
      <Dialog
        className={classes.modal}
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="sm"
      >
      <form
        noValidate={false}
        onSubmit={(e) => {
          e.preventDefault();
          if (itemName) {
            if (item && item.id) {
              dispatch(
                updateItem(
                  listId,
                  {...item, name: itemName, quantity},
                  user));
              handleClose();
            } else {
              dispatch(
                createItem(
                  listId,
                  {name: itemName, quantity, checked: false},
                  user));
              setItemName('');
              setQuantity('');
              handleClose();
            }
          }
        }}>
        <DialogTitle id="alert-dialog-title">
          {item && item.id
            ? locale.t('screens.itemList.modal.header.editMode')
            : locale.t('screens.itemList.modal.header.createMode')}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            required
            margin="dense"
            id="itemName"
            label={locale.t('screens.itemList.modal.input.list.placeholder')}
            type="text"
            fullWidth
            value={itemName}
            onChange={(e) => setItemName(e.target.value)}
            />
          <TextField
            margin="dense"
            id="quantity"
            label={locale.t('screens.itemList.modal.input.item.placeholder')}
            type="text"
            fullWidth
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
            />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>
            {locale.t('buttons.cancel')}
          </Button>
          <Button type="submit">
            {locale.t('buttons.save')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}