import React, {useState, useContext, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {LocalizationContext} from '../locales/Translate';
import {Grid, Divider, List, ListItem, ListItemText, Box} from '@material-ui/core';
import ChevronRight from '@material-ui/icons/ChevronRight';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useTheme} from '@material-ui/core/styles';
import Account from './Account';
import Language from './Language';
import PrivacyPolicy from './PrivacyPolicy';
import About from './About';
import Theme from './Theme';
import {useDispatch} from 'react-redux';
import {logCurrentScreen} from '../store/actions/analytics';

const useStyles = makeStyles((theme) => ({
  classname: {},
  gridSettings: {
    flexGrow: 1,
    minHeight: `calc(100vh - 80px)`,
    borderColor: theme.palette.divider,
  },
  elementContainer: {
    padding: theme.spacing(3)
  },
  row: {
    minHeight: 65,
  },
}));

export default () =>  {
  const theme = useTheme();
  const classes = useStyles();
  const [element, setElement] = useState();
  const [showSettings, setShowSettings] = useState(true);
  const {locale} = useContext(LocalizationContext);
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logCurrentScreen('Settings'));
  }, [dispatch]);

  return (
    <Grid container>
      { (matches || showSettings) &&
        <Grid item xs={12} md={5}>
          <List>
            <Box className={classes.gridSettings} borderRight={matches ? 1 : 0}>
              <ListItem
                button
                className={classes.row}
                onClick={() => {
                  setShowSettings(false)
                  setElement(<Account handleBack={() => setShowSettings(true)}/>)
                }}>
                <ListItemText primary={locale.t('screens.settings.account')} />
                <ChevronRight />
              </ListItem>
              <Divider />
              <ListItem
                button
                className={classes.row}
                onClick={() => {
                  setShowSettings(false)
                  setElement(<Language handleBack={() => setShowSettings(true)}/>)
                }}>
                <ListItemText primary={locale.t('screens.settings.language')} />
                <ChevronRight />
              </ListItem>
              <Divider />
              <ListItem
                button
                className={classes.row}
                onClick={() => {
                  setShowSettings(false)
                  setElement(<PrivacyPolicy handleBack={() => setShowSettings(true)}/>)
                }}>
                <ListItemText primary={locale.t('screens.settings.privacyPolicy')} />
                <ChevronRight />
              </ListItem>
              <Divider />
              <ListItem
                button
                className={classes.row}
                onClick={() => {
                  setShowSettings(false)
                  setElement(<About handleBack={() => setShowSettings(true)}/>)
                }}>
                <ListItemText primary={locale.t('screens.settings.about')} />
                <ChevronRight />
              </ListItem>
              <Divider />
              <ListItem
                button
                className={classes.row}
                onClick={() => {
                  setShowSettings(false)
                  setElement(<Theme handleBack={() => setShowSettings(true)}/>)
                }}>
                <ListItemText primary={locale.t('screens.settings.theme')} />
                <ChevronRight />
              </ListItem>
              <Divider />
            </Box>
          </List>
        </Grid>
      }
      {!showSettings &&
        <Grid item xs={12} md={7}>
          {element}
        </Grid>
      }
    </Grid>
  );
}