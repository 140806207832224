import React from 'react';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@material-ui/core';
import {Link as RouterLink} from "react-router-dom";

export default props => {
  const { icon, primary, to, click, disableToolTip } = props;

  const renderLink = React.useMemo(
    () => React.forwardRef((itemProps, ref) => <RouterLink to={to} ref={ref} {...itemProps} />),
    [to],
  );

  return (
    <ListItem button component={to ? renderLink : null} onClick={click}>
      {icon ?
        <Tooltip
          title={primary}
          aria-label={primary}
          placement="right"
          arrow
          disableHoverListener={disableToolTip}>
          <ListItemIcon>{icon}</ListItemIcon>
        </Tooltip>
        :
        null}
      <ListItemText primary={primary} />
    </ListItem>
  );
}