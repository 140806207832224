import React, {useContext} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {LocalizationContext} from '../locales/Translate';
import {
  Drawer,
  List,
  Divider,
  Hidden,
  IconButton,
  Avatar,
  Typography,
} from '@material-ui/core';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import HelpIcon from '@material-ui/icons/Help';
import Gravatar from 'react-gravatar';
import {drawerWidth} from '../util';
import ListItemLink from './ListItemLink';
import {useSelector, useDispatch} from 'react-redux';
import clsx from 'clsx';
import {toggleMenu} from '../store/actions/menu';

const useStyles = makeStyles((theme) => ({
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  userArea: {
    [theme.breakpoints.down('xs')]: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
    height: '150px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  drawerPaper: {
    minHeight: '100vh',
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  avatar: {
    borderWidth: 3,
    borderRadius: 30,
    width: 60,
    height: 60,
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
}));

export default props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {locale} = useContext(LocalizationContext);
  const user = useSelector(state => state.user);
  const menuOpen = useSelector(state => state.menu.menuOpen);

  const toggleDrawer = () => {
    dispatch(toggleMenu())
  };

  const drawerItems = (
    <List>
      <ListItemLink
        to="/"
        primary={locale.t('menu.home')}
        icon={<FormatListBulletedIcon />}
        click={props.closeMobileDrawer}
        disableToolTip={menuOpen} />
      <ListItemLink
        to="/settings"
        primary={locale.t('menu.settings')}
        icon={<SettingsIcon />}
        click={props.closeMobileDrawer}
        disableToolTip={menuOpen} />
      <ListItemLink
        to="/help"
        primary={locale.t('menu.help')}
        icon={<HelpIcon />}
        click={props.closeMobileDrawer}
        disableToolTip={menuOpen} />
      <ListItemLink
        primary={locale.t('menu.signout')}
        icon={<ExitToAppIcon />}
        disableToolTip={menuOpen}
        click={
          () => {
            props.handleSignOut();
            props.closeMobileDrawer();
          }} />
    </List>
  );

  return (
    <nav>
      {/* Mobie Menu */}
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden smUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="left"
          open={props.mobileDrawerOpen}
          onClose={props.closeMobileDrawer}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <div>
            <div className={classes.userArea}>
              {user && user.photoURL ? (
                <Avatar
                  alt={user.name}
                  src={user.photoURL}
                  className={classes.avatar}
                   />
                ) : (
                <Gravatar
                  className={classes.avatar}
                  email={user.email}
                  size={80}
                  default="mp"
                />
              )}
              <Typography variant="h6">
                {user.name}
              </Typography>
              <Typography variant="caption">
                {user.email}
              </Typography>
            </div>
            <Divider />
            {drawerItems}
          </div>
        </Drawer>
      </Hidden>
      {/* Desk Menu */}
      <Hidden xsDown implementation="css">
        <Drawer
        classes={{
          paper: clsx(classes.drawerPaper, !menuOpen && classes.drawerPaperClose),
        }}
          variant="permanent"
          open={menuOpen}
        >
          <div>
            <div className={classes.toolbarIcon}>
              <IconButton onClick={() => toggleDrawer()}>
              {menuOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            </div>
            <Divider />
            {drawerItems}
          </div>
        </Drawer>
      </Hidden>
    </nav>
  );
}