import {LANGUAGE_CHANGED} from '../actions/actionTypes';

export const initialState = {
  currentLanguage: '',
};

export default (state = initialState, action = null) => {
  if (action.type === LANGUAGE_CHANGED) {
    return {
      ...state,
      currentLanguage: action.payload.language,
    };
  } else {
    return state;
  }
};
