import React, {useContext, useEffect} from 'react';
import {LocalizationContext} from '../locales/Translate';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography, List, ListItem, ListItemText, ListItemIcon, Hidden, Divider} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import Mail from '@material-ui/icons/Mail';
import Public from '@material-ui/icons/Public';
import appMetadata from '../../package.json';
import ListItemSimpleLink from './ListItemSimpleLink';
import {useDispatch} from 'react-redux';
import {logCurrentScreen} from '../store/actions/analytics';

const useStyles = makeStyles((theme) => ({
  title: {
    paddingBottom: theme.spacing(1),
  },
  backTitle: {
    paddingLeft: 0,
    paddingTop: 0,
  },
  listBack: {
    paddingTop: 0,
  },
  dividerTop: {
    marginBottom: 16,
  },
  dividerBottom: {
    marginBottom: 8,
  },
  elementContainer: {
    padding: theme.spacing(3)
  },
}));

export default props =>  {
  const classes = useStyles();
  const {locale} = useContext(LocalizationContext);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logCurrentScreen('About'));
  }, [dispatch]);

  return (
    <Grid container direction="column" className={classes.elementContainer}>
      <Hidden mdUp implementation="css">
        <List className={classes.listBack}>
          <ListItem button className={classes.backTitle} onClick={props.handleBack}>
            <ListItemIcon>
              <ChevronLeft />
            </ListItemIcon>
            <ListItemText primary={locale.t('screens.settings.about')} />
          </ListItem>
        </List>
      </Hidden>
      <Grid item className={classes.title}>
        <Typography paragraph>
          {locale.t('screens.about.version', {version: appMetadata.version})}
        </Typography>
        <Divider className={classes.dividerBottom}/>
      </Grid>
      <Grid item className={classes.title}>
        <Typography paragraph>
          {locale.t('screens.about.contact.title')}
        </Typography>
        <Typography paragraph>
          {locale.t('screens.about.contact.message')}
        </Typography>
        <List>
          <ListItemSimpleLink
            to="mailto:contact@dancortes.com?subject=Contact-MyClubList-Web"
            primary="contact@dancortes.com"
            icon={<Mail />}  />
        </List>
        <Divider className={classes.dividerBottom}/>
      </Grid>
      <Grid item className={classes.title}>
        <Typography paragraph>
          {locale.t('screens.about.more.products')}
        </Typography>
        <List>
          <ListItemSimpleLink
            to="https://www.dancortes.com"
            primary="https://www.dancortes.com"
            icon={<Public />}  />
        </List>
        <Divider className={classes.dividerBottom}/>
      </Grid>
    </Grid>
  );
}