import React, {useState, useContext, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {BrowserRouter} from "react-router-dom";
import {LocalizationContext} from './locales/Translate';
import {useSelector, useDispatch} from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import {THEMES} from './themes';
import Header from './components/Header';
import Menu from './components/Menu';
import Main from './components/Main';
import SignOutModal from './components/SignOutModal';
import SignInOrSignUp from './components/SignInOrSignUp';
import LoadingModal from './components/LoadingModal';
import MessageSnack from './components/MessageSnack';
import {logOpen} from './store/actions/analytics';
import './App.css';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default () => {
  const classes = useStyles();
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  const [signOut, setSignOut] = useState(false);
  const toggleMobileDrawer = () => {
    setMobileDrawerOpen(!mobileDrawerOpen);
  };
  const user = useSelector(state => state.user);
  const currentTheme = useSelector(state => state.theme.currentTheme);
  const theme = createMuiTheme(THEMES[currentTheme]);

  const {initializeAppLanguage, appLanguage} = useContext(LocalizationContext);
  initializeAppLanguage();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(logOpen(user, appLanguage, currentTheme));
  }, [appLanguage, currentTheme, dispatch, user]);

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <LoadingModal />
      <MessageSnack />
      { user.id ?
          <BrowserRouter>
            <div className={classes.root}>
              <Header toggleMobileDrawer={toggleMobileDrawer} />
              <Menu
                mobileDrawerOpen={mobileDrawerOpen}
                closeMobileDrawer={() => setMobileDrawerOpen(false)}
                handleSignOut={() => setSignOut(true)}/>
              <Main />
              <SignOutModal open={signOut} handleClose={() => setSignOut(false)}/>
            </div>
          </BrowserRouter>
        :
          <SignInOrSignUp />
      }
    </MuiThemeProvider>
  );
}
