import React, {useContext, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {LocalizationContext} from '../locales/Translate';
import {useDispatch} from 'react-redux';
import {logCurrentScreen} from '../store/actions/analytics';

const useStyles = makeStyles((theme) => ({
  modal: {},
}));

export default props =>  {
  const {name, handleClose, handleDelete, open} = props;
  const classes = useStyles();
  const {locale} = useContext(LocalizationContext);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logCurrentScreen('DeleteModal'));
  }, [dispatch]);

  return (
      <Dialog
      className={classes.modal}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth="sm"
      >
      <DialogTitle id="alert-dialog-title">
        {locale.t('screens.delete.modal.title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {locale.t('screens.delete.modal.message', {name})}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          {locale.t('screens.delete.button.cancel')}
        </Button>
        <Button
          onClick={() => {
            handleDelete();
            handleClose();
          }}
          autoFocus>
          {locale.t('screens.delete.button.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}