import React, {createContext} from 'react';
import I18n from 'i18n-js';
import en_US from './translations/en_US.json';
import pt_BR from './translations/pt_BR.json';
import es from './translations/es.json';
import {useDispatch, useSelector} from 'react-redux';
import {changeLanguage} from '../store/actions/language';
import detectBrowserLanguage from 'detect-browser-language'

const NORMALIZE_TRANSLATE = {
  en_US: 'en_US',
  "en-US": 'en_US',
  pt_BR: 'pt_BR',
  "pt-BR": 'pt_BR',
  en: 'en_US',
  pt_US: 'pt_BR',
  es: 'es',
};
const DEFAULT_LANGUAGE = NORMALIZE_TRANSLATE.en_US;

I18n.translations = {en_US, pt_BR, es};
I18n.defaultSeparator = '_';

export const availableLanguages = [
  {name: 'English (United States)', value: NORMALIZE_TRANSLATE.en_US},
  {name: 'Português (Brasileiro)', value: NORMALIZE_TRANSLATE.pt_BR},
  {name: 'Español', value: NORMALIZE_TRANSLATE.es},
];

export const LocalizationContext = createContext({
  locale: I18n,
  setAppLanguage: () => {},
  appLanguage: DEFAULT_LANGUAGE,
  initializeAppLanguage: () => {},
});

export const LocalizationProvider = ({children}) => {
  const currentLanguage = useSelector(state => state.language.currentLanguage);
  const dispatch = useDispatch();

  const setLanguage = language => {
    const translateNormalize = NORMALIZE_TRANSLATE[language];
    const iHaveThisLanguage = I18n.translations.hasOwnProperty(
      translateNormalize,
    );
    if (iHaveThisLanguage) {
      I18n.locale = translateNormalize;
      dispatch(changeLanguage(translateNormalize));
    } else {
      I18n.defaultLocale = NORMALIZE_TRANSLATE.en_US;
      dispatch(changeLanguage(NORMALIZE_TRANSLATE.en_US));
    }
  };

  const initializeAppLanguage = async () => {
    if (currentLanguage) {
      setLanguage(currentLanguage);
    } else {
      const language = detectBrowserLanguage();
      setLanguage(language);
    }
  };

  return (
    <LocalizationContext.Provider
      value={{
        locale: I18n,
        setAppLanguage: setLanguage,
        appLanguage: currentLanguage,
        initializeAppLanguage,
      }}>
      {children}
    </LocalizationContext.Provider>
  );
};
