import React from 'react';
import { Backdrop, CircularProgress } from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {useSelector} from 'react-redux';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: '2000',
    color: '#fff',
  },
}));

export default () => {
  const classes = useStyles();
  const isLoading = useSelector(state => state.loading.isLoading);
  return (
    <Backdrop className={classes.backdrop} open={isLoading}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}