import React, {useState, useEffect, useContext} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useDispatch, useSelector} from 'react-redux';
import {loading, loaded} from '../store/actions/loading';
import {Grid, List, Box, Fab, Tooltip} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useTheme} from '@material-ui/core/styles';
import {LocalizationContext} from '../locales/Translate';
import AddIcon from '@material-ui/icons/Add';
import ShoppingList from './ShoppingList';
import ItemList from './ItemList';
import {getListsFirestoreSubscriber} from '../services/shoppingListService';
import ListModal from './ListModal';
import {logCurrentScreen} from '../store/actions/analytics';

const useStyles = makeStyles((theme) => ({
  shoppingListBox: {
    flexGrow: 1,
    borderColor: theme.palette.divider,
    minHeight: `calc(100vh - 99px)`,
  },
  listContainer: {
    height: `calc(100vh - 65px)`,
    overflow: 'scroll',
    position: 'relative',
  },
  addButton: {
    position: 'absolute',
    right: theme.spacing(2),
  },
}));

export default () =>  {
  const theme = useTheme();
  const classes = useStyles();
  const [showItems, setShowItems] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const [scrollList, setScrollList] = useState();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const [activeListId, setActiveListId] = useState();
  const {locale} = useContext(LocalizationContext);

  const handleScroll = event => {
    setScrollList(event.target.scrollTop);
  }

  const handleModalClose = () => {
    setShowModal(false)
  }

  const openModal = () => {
    setShowModal(true)
  }

  const initialLists = [];
  const [lists, setLists] = useState(initialLists);

  useEffect(() => {
    dispatch(loading());
    setLists([]);
    return getListsFirestoreSubscriber(user, listsRetrieved => {
      setLists(listsRetrieved);
      dispatch(loaded());
    });
  },[dispatch, user])

  useEffect(() => {
    dispatch(logCurrentScreen('Home'));
  }, [dispatch]);

  const handleBackItemList = () => {
    setShowItems(true)
    setActiveListId(null);
  }

  const handleListClick = list => {
    setShowItems(false);
    setActiveListId(list.id);
  }

  return (
    <Grid container>
      <ListModal
        handleClose={handleModalClose}
        open={showModal}/>
      { (matches || showItems) &&
        <Grid
          item xs={12} md={5}
          className={classes.listContainer}
          onScroll={handleScroll}>
          <List>
            <Box className={classes.shoppingListBox} borderRight={matches ? 1 : 0}>
              {lists.map((list) => (
                <ShoppingList
                  key={list.id}
                  list={list}
                  activeListId={activeListId}
                  handleListClick={handleListClick}
                  setShowItems={setShowItems}
                  />
              ))}
            </Box>
          </List>
          <Tooltip
            title={locale.t('screens.shoppingList.tooltip.add')}
            aria-label={locale.t('screens.shoppingList.tooltip.add')}
            placement="top-start"
            arrow>
            <Fab
              data-testid="add-shopping-list"
              color="primary"
              aria-label="add"
              className={classes.addButton}
              style={{top: `calc(100vh - ${160-(scrollList||0)}px)`}}
              onClick={openModal}>
              <AddIcon />
            </Fab>
          </Tooltip>
        </Grid>
      }
      {!showItems &&
        <Grid
          item xs={12} md={7}
          className={classes.listContainer}>
          {
            activeListId &&
            lists
              .filter(list => list.id === activeListId)
              .map(list => (
                <ItemList
                  key={list.id}
                  list={list}
                  item={list.item}
                  handleBack={() => handleBackItemList()}
                />
              ))
          }
        </Grid>
      }
    </Grid>
  );
}