import React, {useContext, useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import {Container, Typography, TextField, Button, Grid, Link, Divider} from '@material-ui/core';
import {useDispatch} from 'react-redux';
import {login, signInWithGoogle, signInWithFacebook, signInWithApple} from '../store/actions/user';
import {LocalizationContext} from '../locales/Translate';
import ForgotPasswordModal from './ForgotPasswordModal';
import GoogleIcon from '../assets/imgs/google.svg';
import FacebookIcon from '@material-ui/icons/Facebook';
import {logCurrentScreen} from '../store/actions/analytics';
import PrivacyPolicyModal from './PrivacyPolicyModal';
import {updateAgreement} from '../store/actions/user';
import MyClubListIcon from '../assets/imgs/icon.svg';
import AppleIcon from '@material-ui/icons/Apple';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
  },
  logo: {
    width: 60,
    height: 60,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  socialButton: {
    display: 'flex',
  },
  googleButton: {
    color: theme.palette.getContrastText("#fff"),
    backgroundColor: "#fff",
    '&:hover': {
      backgroundColor: "#eee",
    },
  },
  facebookButton: {
    color: theme.palette.getContrastText("#3b5998"),
    backgroundColor: "#3b5998",
    '&:hover': {
      backgroundColor: "#2f477a",
    },
  },
  socialIcon: {
    flex: 3,
    display: 'flex',
    alignItems: 'center',
  },
  socialText: {
    flex: 10,
  },
  orContainer: {
    display: "flex",
    alignItems: "center",
  },
  orDivider: {
    flex: 2,
  },
  orText: {
    flex: 1,
    textAlign: "center",
  },
  formItem: {
    marginTop: 0,
    marginBottom: theme.spacing(2),
    textAlign: "center",
  },
}));

export default props => {
  const classes = useStyles();
  const {locale} = useContext(LocalizationContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [forgotPassword, setForgotPassword] = useState(false);
  const [userToLoggin, setUserToLoggin] = useState();
  const [updateAgrement, setUpdateAgrement] = useState(false);
  const [openPrivacyPolicyModal, setOpenPrivacyPolicyModal] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logCurrentScreen('SignIn'));
  }, [dispatch]);


  const handleOpenPrivacyPolicyModal = (user, isUpdateAgrement) => {
    setUserToLoggin(user);
    setUpdateAgrement(isUpdateAgrement);
    setOpenPrivacyPolicyModal(true);
  }

  const handleClosePrivacyPolicyModal = () => {
    setOpenPrivacyPolicyModal(false);
  }

  const handleAcceptPrivacyPolicy = () => {
    dispatch(updateAgreement(userToLoggin, true));
  }

  return (
    <div>
      <PrivacyPolicyModal
        open={openPrivacyPolicyModal}
        handleClose={handleClosePrivacyPolicyModal}
        handleAccept={handleAcceptPrivacyPolicy}
        updateAgrement={updateAgrement}  />
      <Container component="main" maxWidth="xs">
        <ForgotPasswordModal open={forgotPassword} handleClose={() => setForgotPassword(false)}/>
        <div className={classes.paper}>
          <div className={classes.avatar}>
            <img src={MyClubListIcon} alt="My Club List" className={classes.logo} />
          </div>
          <Typography component="h2" variant="h5">
            {locale.t('screens.signin.title')}
          </Typography>
          <form
            className={classes.form}
            noValidate={false}
            onSubmit={(e) => {
              e.preventDefault();
              dispatch(login({email, password}, handleOpenPrivacyPolicyModal))
            }}>
            <TextField
              data-testid="email"
              type="email"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label={locale.t('screens.signin.email.placeholder')}
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={classes.formItem}
            />
            <TextField
              data-testid="password"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label={locale.t('screens.signin.password.placeholder')}
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className={classes.formItem}
            />
            <Button
              data-testid="submit"
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={clsx(classes.submit, classes.formItem)}
            >
              {locale.t('screens.signin.button')}
            </Button>
            <Grid item className={classes.formItem}>
              <Link
                data-testid="forgot-password"
                component="button"
                variant="body2"
                onClick={(e) => {
                  e.preventDefault();
                  setForgotPassword(true);
                }}
              >
              {locale.t('screens.signin.forgot.password.text')}
              </Link>
            </Grid>
            <Grid item className={clsx(classes.orContainer, classes.formItem)}>
                <Divider className={classes.orDivider} />
                <Typography
                  className={classes.orText}
                  data-testid="ortext">
                  {locale.t('screens.signin.or.text')}
                </Typography>
                <Divider className={classes.orDivider} />
            </Grid>
            <Grid item className={classes.formItem}>
              <Button
                data-testid="google-signin"
                variant="contained"
                className={clsx(classes.socialButton, classes.googleButton)}
                fullWidth
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(signInWithGoogle(handleOpenPrivacyPolicyModal));
                }}>
                  <div className={classes.socialIcon}>
                    <img src={GoogleIcon} alt="Google Signin" />
                  </div>
                  <div className={classes.socialText}>
                    <Typography>
                      {locale.t('screens.signin.google')}
                    </Typography>
                  </div>
              </Button>
            </Grid>
            <Grid item className={classes.formItem}>
              <Button
                data-testid="facebook-signin"
                variant="contained"
                className={clsx(classes.socialButton, classes.facebookButton)}
                fullWidth
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(signInWithFacebook(handleOpenPrivacyPolicyModal));
                }}>
                  <div className={classes.socialIcon}>
                    <FacebookIcon />
                  </div>
                  <div className={classes.socialText}>
                    <Typography>
                      {locale.t('screens.signin.facebook')}
                    </Typography>
                  </div>
              </Button>
            </Grid>
            <Grid item className={classes.formItem}>
              <Button
                data-testid="apple-signin"
                variant="contained"
                className={clsx(classes.socialButton, classes.googleButton)}
                fullWidth
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(signInWithApple(handleOpenPrivacyPolicyModal));
                }}>
                  <div className={classes.socialIcon}>
                    <AppleIcon />
                  </div>
                  <div className={classes.socialText}>
                    <Typography>
                      {locale.t('screens.signin.apple')}
                    </Typography>
                  </div>
              </Button>
            </Grid>
            <Grid item className={classes.formItem}>
              <Link
                data-testid="signup"
                component="button"
                variant="body2"
                onClick={(e) => {
                  e.preventDefault();
                  props.swap(false);
                }}
              >
                {locale.t('screens.signin.create')}
              </Link>
            </Grid>
          </form>
        </div>
      </Container>
    </div>
  );
}