import {TOGGLE_MENU} from '../actions/actionTypes';

export const initialState = {
  menuOpen: true,
};

export default (state = initialState, action = null) => {
  if (action.type === TOGGLE_MENU) {
    return {
      ...state,
      menuOpen: !state.menuOpen
    };
  } else {
    return state;
  }
};
