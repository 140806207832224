import React, {useState, useContext, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, List, ListItem, ListItemText, ListItemIcon, Hidden, Divider, Fab, Tooltip} from '@material-ui/core';
import {LocalizationContext} from '../locales/Translate';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import AddIcon from '@material-ui/icons/Add';
import ShoppingItem from './ShoppingItem';
import ItemModal from './ItemModal';
import {useDispatch} from 'react-redux';
import {logCurrentScreen} from '../store/actions/analytics';

const useStyles = makeStyles((theme) => ({
  backTitle: {
    paddingLeft: 0,
    paddingTop: 0,
  },
  listBack: {
    paddingTop: 0,
  },
  titleContainer: {
    padding: theme.spacing(3),
    paddingBottom: 0,
  },
  addButton: {
    position: 'fixed',
    right: theme.spacing(2),
    bottom: 40,
  },
}));

export default props =>  {
  const classes = useStyles();
  const {list} = props;
  const [showModal, setShowModal] = useState(false);
  const {locale} = useContext(LocalizationContext);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logCurrentScreen('ItemList'));
  }, [dispatch]);

  const handleModalClose = () => {
    setShowModal(false)
  }

  const openModal = () => {
    setShowModal(true)
  }

  return (
    <Grid container direction="column">
      <ItemModal
        listId={list.id}
        handleClose={handleModalClose}
        open={showModal}/>
      <Hidden mdUp implementation="css">
        <Grid item className={classes.titleContainer}>
          <List>
            <ListItem button className={classes.backTitle} onClick={props.handleBack}>
              <ListItemIcon>
                <ChevronLeft />
              </ListItemIcon>
              <ListItemText primary={list.name} />
            </ListItem>
          </List>
        </Grid>
      </Hidden>
      <Grid item>
        <List>
          <Hidden mdUp implementation="css">
            <Divider />
          </Hidden>
          {list.items.map((item, index) => (
            <ShoppingItem
              listId={list.id}
              key={index}
              item={item} />
          ))}
        </List>
      </Grid>
      <Tooltip
        title={locale.t('screens.itemList.tooltip.add')}
        aria-label={locale.t('screens.itemList.tooltip.add')}
        placement="top-start"
        arrow>
        <Fab
          color="primary"
          aria-label="add"
          className={classes.addButton}
          onClick={() => openModal()}>
          <AddIcon />
        </Fab>
      </Tooltip>
    </Grid>
  );
}