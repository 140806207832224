import React, {useState, useContext} from 'react';
import {Divider, ListItem, ListItemText, Checkbox, Tooltip, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {useDispatch, useSelector} from 'react-redux';
import {deleteList} from '../store/actions/shoppingList';
import {LocalizationContext} from '../locales/Translate';
import clsx from 'clsx';
import ChevronRight from '@material-ui/icons/ChevronRight';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import ListModal from './ListModal';
import DeleteModal from './DeleteModal';

const useStyles = makeStyles((theme) => ({
  controlIcons: {
    paddingRight: 10,
    paddingLeft: 10,
  },
  row: {
    minHeight: 65,
  },
  active: {
    backgroundColor: theme.palette.action.hover,
  },
  textQuantity: {
    marginRight: theme.spacing(1),
  }
}));

export default props =>  {
  const classes = useStyles();
  const {list, handleListClick, activeListId} = props;
  const [checked, setChecked] = useState(false);
  const [showControls, setShowControls] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const {locale} = useContext(LocalizationContext);

  const toggleCheck = () => {
    const checkValue = !checked;
    setChecked(checkValue)
    setShowControls(checkValue);
  }

  const [showListModal, setShowListModal] = useState(false);
  const handleListModalClose = () => {
    setShowListModal(false)
  }
  const openListModal = () => {
    setShowListModal(true)
  }

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleDeleteModalClose = () => {
    setShowDeleteModal(false)
  }
  const openDeleteModal = () => {
    setShowDeleteModal(true)
  }

  const handleDelete = () => {
    dispatch(deleteList(list.id, user));
  }

  return (
    <div>
      <ListModal
        list={list}
        handleClose={handleListModalClose}
        open={showListModal}/>
      <DeleteModal
        name={list.name}
        handleClose={handleDeleteModalClose}
        open={showDeleteModal}
        handleDelete={handleDelete}/>
      <ListItem
        className={clsx(classes.row, (list.id === activeListId) && classes.active)}
        button
        onClick={e => {
          setShowControls(false);
          handleListClick(list);
        }}
        onMouseEnter={() => setShowControls(true)}
        onMouseLeave={() => setShowControls(false)} >
        <Checkbox
          color="default"
          checked={checked}
          onChange={e => toggleCheck()}
          onClick={e => {
            e.stopPropagation();
          }}
        />
        <ListItemText primary={list.name} />
        <Typography className={classes.textQuantity}>
          {list.items.length > 0
            ? `(${list.items.reduce(
                (prev, item) => (item.checked ? prev + 1 : prev + 0),
                0,
              )}/${list.items.length})`
            : ''}
        </Typography>
        {
          (showControls || checked) && (
            <>
              <IconButton
                className={classes.controlIcons}
                onClick={e => {
                  e.stopPropagation();
                  openListModal();
                }}>
                <Tooltip
                  title={locale.t('screens.shoppingList.tooltip.edit')}
                  aria-label={locale.t('screens.shoppingList.tooltip.edit')}
                  arrow>
                  <EditIcon color="action" />
                </Tooltip>
              </IconButton>
              <IconButton
                className={classes.controlIcons}
                onClick={e => {
                  e.stopPropagation();
                  openDeleteModal();
                }}>
                <Tooltip
                  title={locale.t('screens.shoppingList.tooltip.delete')}
                  aria-label={locale.t('screens.shoppingList.tooltip.delete')}
                  arrow>
                  <DeleteIcon color="action" />
                </Tooltip>
              </IconButton>
            </>
          )
        }
        <ChevronRight />
      </ListItem>
      <Divider />
    </div>
  );
}
