import React, {useContext, useEffect, useState} from 'react';
import {LocalizationContext} from '../locales/Translate';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, List, ListItem, ListItemText, ListItemIcon, Hidden, TextField, Button, Divider} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {useDispatch, useSelector} from 'react-redux';
import {updateProfile, deleteUser} from '../store/actions/user';
import {logCurrentScreen} from '../store/actions/analytics';
import DeleteAccountModal from './DeleteAccountModal';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  title: {
    paddingBottom: theme.spacing(1),
  },
  backTitle: {
    paddingLeft: 0,
    paddingTop: 0,
  },
  listBack: {
    paddingTop: 0,
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
  divider: {
    margin: theme.spacing(2, 0, 2),
  },
  elementContainer: {
    padding: theme.spacing(3)
  },
}));

export default props =>  {
  const classes = useStyles();
  const {locale} = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const [email, setEmail] = useState(user.email);
  const [name, setName] = useState(user.name);
  const [deleteAccountModalVisible, setDeleteAccountModalVisible] = useState(
    false,
  );

  useEffect(() => {
    dispatch(logCurrentScreen('Account'));
  }, [dispatch]);

  const closeModal = () => {
    setDeleteAccountModalVisible(false);
  };

  const openModal = () => {
    setDeleteAccountModalVisible(true);
  };

  const handleDelete = () => {
    dispatch(deleteUser(user));
  };

  return (
    <Grid container direction="column" className={classes.elementContainer}>
      <DeleteAccountModal
        open={deleteAccountModalVisible}
        handleClose={closeModal}
        handleDelete={handleDelete} />
      <Hidden mdUp implementation="css">
        <List className={classes.listBack}>
          <ListItem button className={classes.backTitle} onClick={props.handleBack}>
            <ListItemIcon>
              <ChevronLeft />
            </ListItemIcon>
            <ListItemText primary={locale.t('screens.settings.account')} />
          </ListItem>
        </List>
      </Hidden>
      <form
          className={classes.form}
          noValidate={false}
          onSubmit={(e) => {
            e.preventDefault();
            dispatch(updateProfile({...user, name}))
          }}>
          <TextField
            disabled={true}
            data-testid="email"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label={locale.t('screens.signup.email.placeholder')}
            name="email"
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={classes.formItem}
          />
          <TextField
            data-testid="name"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="name"
            label={locale.t('screens.signup.name.placeholder')}
            name="name"
            autoComplete="name"
            autoFocus
            value={name}
            onChange={(e) => setName(e.target.value)}
            className={classes.formItem}
          />
          <Button
            data-testid="submit"
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={clsx(classes.submit, classes.formItem)}
          >
            {locale.t('screens.profile.button')}
          </Button>
      </form>
      <Divider className={classes.divider}/>
      <Button
        data-testid="exclude-account"
        fullWidth
        variant="contained"
        color="secondary"
        className={clsx(classes.submit, classes.formItem)}
        onClick={openModal}
      >
        {locale.t('screens.profile.deleteAccount')}
      </Button>
      <Divider className={classes.divider}/>
    </Grid>
  );
}