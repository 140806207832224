export const LIST_CREATED = 'LIST_CREATED';
export const LIST_DELETED = 'LIST_DELETED';
export const LIST_UPDATED = 'LIST_UPDATED';
export const LIST_OPENED = 'LIST_OPENED';
export const ITEM_TOGGLE = 'ITEM_TOGGLE';
export const ITEM_CREATED = 'ITEM_CREATED';
export const ITEM_DELETED = 'ITEM_DELETED';
export const ITEM_UPDATED = 'ITEM_UPDATED';
export const LISTS_OVERWRITTEN = 'LISTS_OVERWRITTEN';
export const CURRENT_LIST_UPDATED = 'CURRENT_LIST_UPDATED';

export const LANGUAGE_CHANGED = 'LANGUAGE_CHANGED';

export const THEME_CHANGED = 'THEME_CHANGED';
export const THEME_RESTORED = 'THEME_RESTORED';

export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';

export const LOADING = 'LOADING';
export const LOADED = 'LOADED';

export const MESSAGE_SETTED = 'MESSAGE_SETTED';

export const TOGGLE_MENU = 'TOGGLE_MENU';
