import React, {useContext, useEffect} from 'react';
import {LocalizationContext, availableLanguages} from '../locales/Translate';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography, FormControl, RadioGroup, FormControlLabel, Radio, List, ListItem, ListItemText, ListItemIcon, Hidden} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {useDispatch} from 'react-redux';
import {logCurrentScreen} from '../store/actions/analytics';

const useStyles = makeStyles((theme) => ({
  title: {
    paddingBottom: theme.spacing(1),
  },
  backTitle: {
    paddingLeft: 0,
    paddingTop: 0,
  },
  listBack: {
    paddingTop: 0,
  },
  elementContainer: {
    padding: theme.spacing(3)
  },
}));

export default props =>  {
  const classes = useStyles();
  const {locale, appLanguage, setAppLanguage} = useContext(LocalizationContext);
  const handleChange = (event) => {
    setAppLanguage(event.target.value);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logCurrentScreen('Language'));
  }, [dispatch]);

  return (
    <Grid container direction="column" className={classes.elementContainer}>
      <Hidden mdUp implementation="css">
        <List className={classes.listBack}>
          <ListItem button className={classes.backTitle} onClick={props.handleBack}>
            <ListItemIcon>
              <ChevronLeft />
            </ListItemIcon>
            <ListItemText primary={locale.t('screens.settings.language')} />
          </ListItem>
        </List>
      </Hidden>
      <Grid item xs className={classes.title}>
        <Typography paragraph>
          {locale.t('screens.language.title')}
        </Typography>
      </Grid>
      <FormControl component="fieldset">
        <RadioGroup aria-label="language" name="language" value={appLanguage} onChange={handleChange}>
          {availableLanguages.map((language, index) => (
            <FormControlLabel key={index} value={language.value} control={<Radio />} label={language.name} />
          ))}
        </RadioGroup>
      </FormControl>
    </Grid>
  );
}