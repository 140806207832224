import {firebaseImpl} from '../config/Firebase';
import {generateCurrentUnixTimestamp} from '../util/helperFunctions';
import {lastPrivacyPolicyDate} from '../util/privacyPolicy';
import {userCollection} from './collections';

export const getUserService = async user => {
  let userData = null;

  const documentSnapshot = await firebaseImpl.firestore()
    .collection(userCollection)
    .doc(user.id)
    .get();

  if (documentSnapshot && documentSnapshot.exists) {
    userData = {
      ...documentSnapshot.data(),
    };

    if (userData.shoppingLists) {
      delete userData.shoppingLists;
    }
  }

  return userData;
}

export const createUserService = (
  user,
  agreement,
  success,
  error,
  always,
) => {

  const currentUnixTimestamp = generateCurrentUnixTimestamp();
  const userData = {
    name: user.name,
    email: user.email,
    agreement,
    agreementAt: currentUnixTimestamp,
    createdAt: currentUnixTimestamp,
    updatedAt: currentUnixTimestamp,
    privacyPolicyDate: lastPrivacyPolicyDate,
  };
  //console.log('Creating user at firestore...');
  firebaseImpl
    .firestore()
    .collection(userCollection)
    .doc(user.id)
    .set(userData)
    .then(() => {
      //console.log('User successfully created');
      success && success();
    })
    .catch(err => {
      //console.log('Error trying to create user at Firestore: ', err);
      error && error(err);
    })
    .finally(() => {
      always && always();
    });

};

export const updateUserService = async (
  user,
  agreement,
  success,
  error,
  always,
) => {
  //console.log('Checking if user already exists at Firestore...', user);
  let userData = await getUserService(user);

  if (userData) {
    //console.log('User exists.');
    const currentUnixTimestamp = generateCurrentUnixTimestamp();
    userData = {
      name: user.name ? user.name : userData.name,
      email: userData.email,
      agreement: !agreement ? userData.agreement : agreement,
      agreementAt: !agreement ? userData.agreementAt : currentUnixTimestamp,
      updatedAt: currentUnixTimestamp,
      createdAt: userData.createdAt,
      privacyPolicyDate: lastPrivacyPolicyDate,
    };
    //console.log('Updating user at firestore...');
    firebaseImpl
      .firestore()
      .collection(userCollection)
      .doc(user.id)
      .set(userData)
      .then(() => {
        //console.log('User successfully updated');
        success && success();
      })
      .catch(err => {
        //console.log('Error trying to update user at Firestore: ', err);
        error && error(err);
      })
      .finally(() => {
        always && always();
      });
  } else {
    //console.log('User does not exists, lets create it');
    const currentUnixTimestamp = generateCurrentUnixTimestamp();
    userData = {
      name: user.name,
      email: user.email,
      agreement: agreement,
      agreementAt: currentUnixTimestamp,
      updatedAt: currentUnixTimestamp,
      createdAt: currentUnixTimestamp,
      privacyPolicyDate: lastPrivacyPolicyDate,
    };
    //console.log('Creating user at firestore...');
    firebaseImpl
      .firestore()
      .collection(userCollection)
      .doc(user.id)
      .set(userData)
      .then(() => {
        //console.log('User successfully created');
        success && success();
      })
      .catch(err => {
        //console.log('Error trying to create user at Firestore: ', err);
        error && error(err);
      })
      .finally(() => {
        always && always();
      });
  }
};
