import React, {useContext, useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import {Typography, TextField, Button, Grid, Link, Container, Divider} from '@material-ui/core';
import {useDispatch} from 'react-redux';
import {
  createUser,
  signUpWithGoogle,
  signUpWithFacebook,
  signUpWithApple,
} from '../store/actions/user';
import {errorMessage} from '../store/actions/message';
import {LocalizationContext} from '../locales/Translate';
import GoogleIcon from '../assets/imgs/google.svg';
import FacebookIcon from '@material-ui/icons/Facebook';
import {logCurrentScreen} from '../store/actions/analytics';
import PrivacyPolicyModal from './PrivacyPolicyModal';
import MyClubListIcon from '../assets/imgs/icon.svg';
import AppleIcon from '@material-ui/icons/Apple';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
  },
  logo: {
    width: 60,
    height: 60,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  socialButton: {
    display: 'flex',
  },
  googleButton: {
    color: theme.palette.getContrastText("#fff"),
    backgroundColor: "#fff",
    '&:hover': {
      backgroundColor: "#eee",
    },
  },
  facebookButton: {
    color: theme.palette.getContrastText("#3b5998"),
    backgroundColor: "#3b5998",
    '&:hover': {
      backgroundColor: "#2f477a",
    },
  },
  socialIcon: {
    flex: 3,
    display: 'flex',
    alignItems: 'center',
  },
  socialText: {
    flex: 10,
  },
  orContainer: {
    display: "flex",
    alignItems: "center",
  },
  orDivider: {
    flex: 2,
  },
  orText: {
    flex: 1,
    textAlign: "center",
  },
  formItem: {
    marginTop: 0,
    marginBottom: theme.spacing(2),
    textAlign: "center",
  },
}));

export default props => {
  const classes = useStyles();
  const {locale} = useContext(LocalizationContext);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [openPrivacyPolicyModal, setOpenPrivacyPolicyModal] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [signUpType, setSignUpType] = useState();
  const dispatch = useDispatch();
  const PASSWORD = 'password';
  const GOOGLE = 'google';
  const FACEBOOK = 'facebook';
  const APPLE = 'apple';

  useEffect(() => {
    dispatch(logCurrentScreen('DeleteModal'));
  }, [dispatch]);

  const handleOpenPrivacyPolicyModal = type => {
    setSignUpType(type);
    setOpenPrivacyPolicyModal(true);
  }

  const handleClosePrivacyPolicyModal = () => {
    setOpenPrivacyPolicyModal(false);
  }

  const handleAcceptPrivacyPolicy = type => {
    switch (type) {
      case PASSWORD:
        dispatch(createUser({name, email, password}));
        break;
      case GOOGLE:
        dispatch(signUpWithGoogle());
        break;
      case FACEBOOK:
        dispatch(signUpWithFacebook());
        break;
      case APPLE:
        dispatch(signUpWithApple());
        break;
      default:
        break;
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <PrivacyPolicyModal
        open={openPrivacyPolicyModal}
        handleClose={handleClosePrivacyPolicyModal}
        handleAccept={() => handleAcceptPrivacyPolicy(signUpType)}  />
      <div className={classes.paper}>
        <div className={classes.avatar}>
          <img src={MyClubListIcon} alt="My Club List" className={classes.logo} />
        </div>
        <Typography component="h2" variant="h5">
          {locale.t('screens.signup.title')}
        </Typography>
        <form
          className={classes.form}
          noValidate={false}
          onSubmit={(e) => {
            e.preventDefault();
            if (password === confirmPassword) {
              handleOpenPrivacyPolicyModal(PASSWORD);
            } else {
              dispatch(errorMessage('error.password.not.confirm'))
            }
          }}>
          <TextField
            data-testid="name"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="name"
            label={locale.t('screens.signup.name.placeholder')}
            name="name"
            autoComplete="name"
            autoFocus
            value={name}
            onChange={(e) => setName(e.target.value)}
            className={classes.formItem}
          />
          <TextField
            data-testid="email"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label={locale.t('screens.signup.email.placeholder')}
            name="email"
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={classes.formItem}
          />
          <TextField
            data-testid="password"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label={locale.t('screens.signup.password.placeholder')}
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className={classes.formItem}
          />
          <TextField
            data-testid="confirm-password"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="confirm-password"
            label={locale.t('screens.signup.confirmPassword.placeholder')}
            type="password"
            id="confirm-password"
            autoComplete="current-password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className={classes.formItem}
          />
          <Button
            data-testid="submit"
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={clsx(classes.submit, classes.formItem)}
          >
            {locale.t('screens.signup.button')}
          </Button>

          <Grid item className={clsx(classes.orContainer, classes.formItem)}>
              <Divider className={classes.orDivider} />
              <Typography className={classes.orText} data-testid="ortext">
                {locale.t('screens.signup.or.text')}
              </Typography>
              <Divider className={classes.orDivider} />
          </Grid>
          <Grid item className={classes.formItem}>
            <Button
              data-testid="google-signup"
              variant="contained"
              className={clsx(classes.socialButton, classes.googleButton)}
              fullWidth
              onClick={(e) => {
                e.preventDefault();
                handleOpenPrivacyPolicyModal(GOOGLE);
              }}>
                <div className={classes.socialIcon}>
                  <img src={GoogleIcon} alt="Google Signup" />
                </div>
                <div className={classes.socialText}>
                  <Typography>
                    {locale.t('screens.signup.google')}
                  </Typography>
                </div>
            </Button>
          </Grid>
          <Grid item className={classes.formItem}>
            <Button
              data-testid="facebook-signup"
              variant="contained"
              className={clsx(classes.socialButton, classes.facebookButton)}
              color="primary"
              fullWidth
              onClick={(e) => {
                e.preventDefault();
                handleOpenPrivacyPolicyModal(FACEBOOK);
              }}>
                <div className={classes.socialIcon}>
                  <FacebookIcon />
                </div>
                <div className={classes.socialText}>
                  <Typography>
                    {locale.t('screens.signup.facebook')}
                  </Typography>
                </div>
            </Button>
          </Grid>
          <Grid item className={classes.formItem}>
            <Button
              data-testid="apple-signup"
              variant="contained"
              className={clsx(classes.socialButton, classes.googleButton)}
              fullWidth
              onClick={(e) => {
                e.preventDefault();
                handleOpenPrivacyPolicyModal(APPLE);
              }}>
                <div className={classes.socialIcon}>
                  <AppleIcon />
                </div>
                <div className={classes.socialText}>
                  <Typography>
                    {locale.t('screens.signup.apple')}
                  </Typography>
                </div>
            </Button>
          </Grid>
          <Grid item className={classes.formItem}>
            <Link
              data-testid="signin"
              component="button"
              variant="body2"
              onClick={(e) => {
                e.preventDefault();
                props.swap();
              }}

            >
            {locale.t('screens.signup.alreadyHasUser')}
            </Link>
          </Grid>
        </form>
      </div>
    </Container>
  );
}