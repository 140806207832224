import React, {useContext, useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useDispatch, useSelector} from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {LocalizationContext} from '../locales/Translate';
import {TextField} from '@material-ui/core';
import {createList, updateList} from '../store/actions/shoppingList';
import {logCurrentScreen} from '../store/actions/analytics';

const useStyles = makeStyles((theme) => ({
  modal: {},
}));

export default props =>  {
  const {list, handleClose, open} = props;
  const classes = useStyles();
  const {locale} = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const [listName, setListName] = useState(list && list.name ? list.name : '');
  const user = useSelector(state => state.user);

  useEffect(() => {
    dispatch(logCurrentScreen('ListModal'));
  }, [dispatch]);

  return (
      <Dialog
        className={classes.modal}
        open={open}
        onClose={() => {
          handleClose();
          setListName(list && list.name ? list.name : '')
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="sm"
      >
      <form
        noValidate={false}
        onSubmit={(e) => {
          e.preventDefault();
          if (listName) {
            if (list && list.id) {
              dispatch(updateList({...list, name: listName}, user));
              handleClose();
            } else {
              dispatch(createList(listName, user));
              handleClose();
              setListName('');
            }
          }
        }}>
        <DialogTitle id="alert-dialog-title" data-testid="add-list-title">
          {list && list.id
            ? locale.t('screens.shoppingList.modal.header.editMode')
            : locale.t('screens.shoppingList.modal.header.createMode')}
        </DialogTitle>
        <DialogContent>
          <TextField
            data-testid="list-name"
            autoFocus
            required
            margin="dense"
            id="listName"
            label={locale.t('screens.shoppingList.modal.input.placeholder')}
            type="text"
            fullWidth
            value={listName}
            onChange={(e) => setListName(e.target.value)}
            />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            handleClose();
            setListName(list && list.name ? list.name : '');
          }}>
            {locale.t('buttons.cancel')}
          </Button>
          <Button data-testid="submit" type="submit">
            {locale.t('buttons.save')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}