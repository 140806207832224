import {firebaseImpl} from '../config/Firebase';
import {
  generateId,
  generateCurrentUnixTimestamp,
} from '../util/helperFunctions';
import {userCollection, shoppingListCollection} from './collections';

export const createListService = (listName, user, success, error, always) => {
  // console.log('Creating list at Firestore...');
  firebaseImpl.firestore()
    .collection(userCollection)
    .doc(user.id)
    .collection(shoppingListCollection)
    .doc(generateId())
    .set({
      name: listName,
      items: [],
      userId: user.id,
      createdAt: generateCurrentUnixTimestamp(),
      updatedAt: generateCurrentUnixTimestamp(),
    })
    .then(() => {
      // console.log('List successfully created');
      success && success();
    })
    .catch(err => {
      // console.log('Error trying to create list at Firestore: ', err);
      error && error(err);
    })
    .finally(() => {
      always && always();
    });
};

export const deleteListService = (id, user, success, error, always) => {
  // console.log('Deleting list at Firestore...');
  firebaseImpl.firestore()
    .collection(userCollection)
    .doc(user.id)
    .collection(shoppingListCollection)
    .doc(id)
    .delete()
    .then(() => {
      // console.log('List successfully deleted');
      success && success();
    })
    .catch(err => {
      // console.log('Error trying to delete list at Firestore: ', err);
      error && error(err);
    })
    .finally(() => {
      always && always();
    });
};

export const deleteUserService = async (user, success, error, always) => {
  //console.log('Deleting user at Firestore...');
  let querySnapshot;
  try {
    //console.log('Deleting all lists for user at Firestore...');
    querySnapshot = await firebaseImpl.firestore()
      .collection(userCollection)
      .doc(user.id)
      .collection(shoppingListCollection)
      .get();
    if (querySnapshot && querySnapshot.size > 0) {
      querySnapshot.forEach(async documentSnapshot => {
        await firebaseImpl.firestore()
          .collection(userCollection)
          .doc(user.id)
          .collection(shoppingListCollection)
          .doc(documentSnapshot.id)
          .delete();
      });
      //console.log('Lists successfully deleted.');
    } else {
      //console.log('There is no list to delete.');
    }
  } catch (err) {
    //console.log('Error trying to delete user list at Firestore: ', err);
    error && error(err);
    always && always();
    return;
  }

  firebaseImpl.firestore()
    .collection(userCollection)
    .doc(user.id)
    .delete()
    .then(() => {
      //console.log('User successfully deleted');
      success && success();
    })
    .catch(err => {
      //console.log('Error trying to delete user at Firestore: ', err);
      error && error(err);
    })
    .finally(() => {
      always && always();
    });
};

export const updateListService = (list, user, success, error, always) => {
  //console.log('Updating list at Firestore...');
  firebaseImpl.firestore()
    .collection(userCollection)
    .doc(user.id)
    .collection(shoppingListCollection)
    .doc(list.id)
    .update({
      name: list.name,
      items: list.items,
      updatedAt: generateCurrentUnixTimestamp(),
      userId: user.id,
    })
    .then(() => {
      //console.log('List successfully updated');
      success && success();
    })
    .catch(err => {
      //console.log('Error trying to update list at Firestore: ', err);
      error && error(err);
    })
    .finally(() => {
      always && always();
    });
};

export const toggleItemService = async (
  listId,
  itemId,
  user,
  success,
  error,
  always,
) => {
  // console.log('Toggle item in list at Firestore...');
  let documentSnapshot;
  try {
    documentSnapshot = await firebaseImpl.firestore()
      .collection(userCollection)
      .doc(user.id)
      .collection(shoppingListCollection)
      .doc(listId)
      .get();

    if (documentSnapshot && documentSnapshot.exists) {
      const list = documentSnapshot.data();
      if (list.items && list.items.length > 0) {
        const items = list.items.map(item =>
          item.id === itemId ? {...item, checked: !item.checked} : item,
        );
        list.id = listId;
        list.items = items;
        // console.log('Item toggle ok, lets update the list...');
        updateListService(list, user, success ? () => success(list) : null, error, always);
      } else {
        // console.log('No items in the list to toggle...');
      }
    } else {
      throw new Error(`No data was found for listId:${listId}`);
    }
  } catch (err) {
    // console.log('Error trying to toggle item in list at Firestore: ', err);
    error && error(err);
    always && always();
    return;
  }
};

export const createItemService = async (
  listId,
  item,
  user,
  success,
  error,
  always,
) => {
  // console.log('Creating item in list at Firestore... ', listId);
  let documentSnapshot;
  try {
    documentSnapshot = await firebaseImpl.firestore()
      .collection(userCollection)
      .doc(user.id)
      .collection(shoppingListCollection)
      .doc(listId)
      .get();

    // console.log('Document returned: ', documentSnapshot);

    if (documentSnapshot && documentSnapshot.exists) {
      const list = documentSnapshot.data();
      list.id = listId;
      list.items.push({...item, id: generateId()});
      /*
      console.log(
        'Item created and included in the list, lets update the list...',
      );
      */
      updateListService(list, user, success ? () => success(list) : null, error, always);
    } else {
      throw new Error(`No data was found for listId:${listId}`);
    }
  } catch (err) {
    // console.log('Error trying to create item in list at Firestore: ', err);
    error && error(err);
    always && always();
    return;
  }
};

export const deleteItemService = async (
  listId,
  itemId,
  user,
  success,
  error,
  always,
) => {
  // console.log('Deleting item in list at Firestore...');
  let documentSnapshot;
  try {
    documentSnapshot = await firebaseImpl.firestore()
      .collection(userCollection)
      .doc(user.id)
      .collection(shoppingListCollection)
      .doc(listId)
      .get();

    if (documentSnapshot && documentSnapshot.exists) {
      const list = documentSnapshot.data();
      if (list.items && list.items.length > 0) {
        const items = list.items.filter(item => item.id !== itemId);
        list.id = listId;
        list.items = items;

        // console.log('Item delete in the list, lets update the list...');
        updateListService(list, user, success ? () => success(list) : null, error, always);
      } else {
        // console.log('There is no item in the list to delete...');
      }
    } else {
      throw new Error(`No data was found for listId:${listId}`);
    }
  } catch (err) {
    // console.log('Error trying to delete item in list at Firestore: ', err);
    error && error(err);
    always && always();
    return;
  }
};

export const updateItemService = async (
  listId,
  item,
  user,
  success,
  error,
  always,
) => {
  // console.log('Deleting item in list at Firestore...');
  let documentSnapshot;
  try {
    documentSnapshot = await firebaseImpl.firestore()
      .collection(userCollection)
      .doc(user.id)
      .collection(shoppingListCollection)
      .doc(listId)
      .get();

    if (documentSnapshot && documentSnapshot.exists) {
      const list = documentSnapshot.data();
      if (list.items && list.items.length > 0) {
        const items = list.items.map(curItem =>
          curItem.id === item.id ? item : curItem,
        );
        list.id = listId;
        list.items = items;

        // console.log('Item updated in the list, lets update the list...');
        updateListService(list, user, success ? () => success(list) : null, error, always);
      }
    } else {
      throw new Error(`No data was found for listId:${listId}`);
    }
  } catch (err) {
    // console.log('Error trying to update item in list at Firestore: ', err);
    error && error(err);
    always && always();
    return;
  }
};

export const getListsFirestoreSubscriber = (user, callback) => {
  // console.log('Starting Subscriber to Firestore...');
  return firebaseImpl.firestore()
    .collection(userCollection)
    .doc(user.id)
    .collection(shoppingListCollection)
    .onSnapshot(
      querySnapshot => {
        const lists = [];
        if (querySnapshot) {
          querySnapshot.forEach(documentSnapshot => {
            lists.push({
              ...documentSnapshot.data(),
              id: documentSnapshot.id,
            });
            lists.sort((a, b) => b.updatedAt - a.updatedAt);
          });
        }
        // console.log('Subscriber to Firestore successfully started.');
        callback && callback(lists);
      },
      /*
      err => {
        console.log('Error trying to subscribe to Firestore: ', err);
      },
      */
    );
};
