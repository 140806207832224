import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Switch,Route} from "react-router-dom";
import Home from './Home';
import Settings from './Settings';
import Help from './Help';

const useStyles = makeStyles((theme) => ({
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    minHeight: '100vh',
    overflow: 'auto',
  },
}));

export default () => {
  const classes = useStyles();
  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/settings">
          <Settings />
        </Route>
        <Route path="/help">
          <Help />
        </Route>
      </Switch>
    </main>
  );
}
