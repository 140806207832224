import React, {useState, useContext, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, List, Box, ListItem, ListItemText, Divider, Hidden, ListItemIcon, Typography} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {useTheme} from '@material-ui/core/styles';
import {LocalizationContext} from '../locales/Translate';
import clsx from 'clsx';
import {useDispatch} from 'react-redux';
import {logCurrentScreen, logQuestion} from '../store/actions/analytics';

const useStyles = makeStyles((theme) => ({
  shoppingListBox: {
    flexGrow: 1,
    borderColor: theme.palette.divider,
    minHeight: `calc(100vh - 99px)`,
  },
  listContainer: {
    height: `calc(100vh - 65px)`,
    overflow: 'scroll',
    position: 'relative',
  },
  addButton: {
    position: 'absolute',
    right: theme.spacing(2),
  },
  row: {
    minHeight: 65,
  },
  active: {
    backgroundColor: theme.palette.action.hover,
  },
  backTitle: {
    paddingLeft: 0,
    paddingTop: 0,
  },
  listBack: {
    paddingTop: 0,
  },
  titleContainer: {
    padding: theme.spacing(3),
    paddingBottom: 0,
  },
  elementContainer: {
    padding: theme.spacing(3)
  },
  answerTitle: {
    paddingBottom: theme.spacing(3),
  }
}));

const faqs = [
  {
    id: 1,
    question: 'screens.help.faq.question.1',
    answer: 'screens.help.faq.answer.1',
  },
  {
    id: 2,
    question: 'screens.help.faq.question.2',
    answer: 'screens.help.faq.answer.2',
  },
  {
    id: 3,
    question: 'screens.help.faq.question.3',
    answer: 'screens.help.faq.answer.3',
  },
  {
    id: 4,
    question: 'screens.help.faq.question.4',
    answer: 'screens.help.faq.answer.4',
  },
  {
    id: 5,
    question: 'screens.help.faq.question.5',
    answer: 'screens.help.faq.answer.5',
  },
  {
    id: 6,
    question: 'screens.help.faq.question.6',
    answer: 'screens.help.faq.answer.6',
  },
  {
    id: 7,
    question: 'screens.help.faq.question.7',
    answer: 'screens.help.faq.answer.7',
  },
  {
    id: 8,
    question: 'screens.help.faq.question.8',
    answer: 'screens.help.faq.answer.8',
  },
];

export default () =>  {
  const theme = useTheme();
  const classes = useStyles();
  const [showItems, setShowItems] = useState(true);
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const [activeFaqId, setActiveFaqId] = useState();
  const {locale} = useContext(LocalizationContext);
  const dispatch = useDispatch();

  const handleBack = () => {
    setShowItems(true)
    setActiveFaqId(null);
  }

  const handleClick = faq => {
    setShowItems(false);
    setActiveFaqId(faq.id);
    const faqFiltered = faqs.filter(q => q.id === faq.id)
    const question = (faqFiltered && faqFiltered.length > 0) ? faqFiltered[0] : null;
    question && dispatch(logQuestion(question.id, locale.t(question.question, {num: question.id})));
  }

  useEffect(() => {
    dispatch(logCurrentScreen('Help'));
  }, [dispatch]);

  return (
    <Grid container>
      { (matches || showItems) &&
        <Grid
          item xs={12} md={5}
          className={classes.listContainer}>
          <List>
            <Box className={classes.shoppingListBox} borderRight={matches ? 1 : 0}>
              {faqs.map((faq) => (
                <div key={faq.id}>
                  <ListItem
                    className={clsx(classes.row, (faq.id === activeFaqId) && classes.active)}
                    button
                    onClick={e => {
                      handleClick(faq);
                    }}>
                    <ListItemText primary={locale.t(faq.question, {num: faq.id})} />
                  </ListItem>
                  <Divider />
                </div>
              ))}
            </Box>
          </List>
        </Grid>
      }
      {
        !showItems &&
        <Grid
          item xs={12} md={7}
          className={classes.listContainer}>
          <Grid container direction="column">
            <Hidden mdUp implementation="css">
              <Grid item className={classes.titleContainer}>
                <List>
                  <ListItem button className={classes.backTitle} onClick={handleBack}>
                    <ListItemIcon>
                      <ChevronLeft />
                    </ListItemIcon>
                    <ListItemText primary={locale.t('screens.help.back')} />
                  </ListItem>
                </List>
              </Grid>
            </Hidden>
            <Grid item  className={classes.elementContainer}>
              {
                activeFaqId &&
                faqs
                  .filter(faq => faq.id === activeFaqId)
                  .map(faq => (
                    <div key={faq.id}>
                      <Typography variant="h6" className={classes.answerTitle}>
                        {locale.t(faq.question, {num: faq.id})}
                      </Typography>
                      <Typography paragraph >
                        {locale.t(faq.answer)}
                      </Typography>
                    </div>
                  ))
              }
            </Grid>
          </Grid>
        </Grid>
      }
    </Grid>
  );
}