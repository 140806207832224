import {USER_LOGGED_IN, USER_LOGGED_OUT} from './actionTypes';
import {
  firebaseImpl,
  googleProvider,
  facebookProvider,
  appleProvider,
} from '../../config/Firebase';
import {loaded, loading} from './loading';
import {errorMessage, warningMessage, infoMessage} from './message';
import {
  logLoginUserPassword,
  logLoginGoogle,
  logLoginFacebook,
  logLoginApple,
  logRecoverPassword,
  logSignUpUserPassword,
  logError,
} from './analytics';
import {deleteUserService} from '../../services/shoppingListService';
import {createUserService, getUserService, updateUserService} from '../../services/userService';
import {lastPrivacyPolicyDate} from '../../util/privacyPolicy';

export const loggedIn = user => ({
  type: USER_LOGGED_IN,
  payload: {user},
});

export const loggedOut = () => ({
  type: USER_LOGGED_OUT,
});

export const createUser = (user) => {
  return dispatch => {
    dispatch(loading());
    firebaseImpl.auth()
      .createUserWithEmailAndPassword(user.email, user.password)
      .then(resp => {
        firebaseImpl.auth()
          .currentUser.updateProfile({
            displayName: user.name,
          })
          .then(() => {
            const userToLogin = {...user, id: resp.user.uid};
            createUserService(
              userToLogin,
              true,
              // Success
              null,
              // Error
              (error) => {
                dispatch(logError(error));
              },
              // Always
              () => {
                dispatch(loggedIn(userToLogin));
                dispatch(loaded());
                dispatch(logSignUpUserPassword());
              }
            );
          })
          .catch(error => {
            dispatch(logError(error));
            dispatch(errorMessage('error.generic'));
            dispatch(loaded());
          });
      })
      .catch(error => {
        dispatch(logError(error));
        if (error && error.code) {
          switch (error.code) {
            case 'auth/email-already-in-use':
              dispatch(warningMessage('error.address.already.use'));
              break;
            case 'auth/invalid-email':
              dispatch(errorMessage('error.address.is.invalid'));
              break;
            case 'auth/weak-password':
              dispatch(warningMessage('error.weak.password'));
              break;
            default:
              dispatch(errorMessage('error.generic'));
              break;
          }
        } else {
          dispatch(errorMessage('error.generic'));
        }
        dispatch(loaded());
      });
  };
};

export const updateAgreement = (user, agreement) => {
  return dispatch => {
    dispatch(loading());
    updateUserService(
      user,
      agreement,
      // Success
      () => {
        dispatch(loggedIn(user));
        dispatch(logLoginUserPassword());
      },
      // Error
      (error) => {
        dispatch(logError(error));
        dispatch(errorMessage('error.generic'));
      },
      // Always
      () => {
        dispatch(loaded());
      },
    )
  }
}

export const updateProfile = (user, callback) => {
  return dispatch => {
    dispatch(loading());
    const unsubscribe = firebaseImpl
      .auth()
      .onAuthStateChanged(userToUpdate => {
        if (userToUpdate) {
          userToUpdate
            .updateProfile({
              displayName: user.name,
            })
            .then(resp => {
              updateUserService(
                user,
                // Agreement
                null,
                // Success
                () => {
                  dispatch(loggedIn(user));
                  callback && callback();
                },
                // Error
                (error) => {
                  dispatch(logError(error));
                  dispatch(errorMessage('error.generic'));
                },
                // Always
                () => {
                  dispatch(loaded());
                },
              )
            })
            .catch(error => {
              dispatch(logError(error));
              dispatch(errorMessage('error.generic'));
              dispatch(loaded());
            })
            .finally(() => {
              unsubscribe();
            });
        }
      })
  };
};

export const deleteUser = (user, callback) => {
  return dispatch => {
    dispatch(loading());
    deleteUserService(
      user,
      null,
      error => {
        dispatch(logError(error));
      },
      () => {
        firebaseImpl
          .auth()
            .currentUser
              .delete()
              .then(() => {
                dispatch(logout());
                callback && callback();
                dispatch(loaded());
              })
              .catch(error => {
                dispatch(logError(error));
                if (error && error.code) {
                  switch (error.code) {
                    case 'auth/requires-recent-login':
                      dispatch(warningMessage('error.requires.recent.login'));
                      dispatch(logout());
                      callback && callback();
                      break;
                    case 'auth/invalid-email':
                      dispatch(errorMessage('error.address.is.invalid'));
                      break;
                    default:
                      dispatch(errorMessage('error.generic'));
                      break;
                  }
                } else {
                  dispatch(errorMessage('error.generic'));
                }
                dispatch(loaded());
              });

      },
    );
  };
};

export const login = (user, handleAgreement) => {
  return dispatch => {
    dispatch(loading());
    firebaseImpl.auth()
      .signInWithEmailAndPassword(user.email, user.password)
      .then(async resp => {
        const userToLoggin = {
          ...user,
          name: resp.user.displayName,
          id: resp.user.uid,
        };
        const userFromDb = await getUserService(userToLoggin);
        //console.log('User from DB:', userFromDb);
        if (userFromDb && !userFromDb.agreement) {
          // User must set agreement
          //console.log('User must set agreement');
          dispatch(loaded());
          handleAgreement && handleAgreement(userToLoggin, false);
        } else if (userFromDb && userFromDb.privacyPolicyDate < lastPrivacyPolicyDate) {
          // User must update the agreement
          dispatch(loaded());
          //console.log('User must update the agreement');
          handleAgreement && handleAgreement(userToLoggin, true);
        } else {
          // User can login
          dispatch(loggedIn(userToLoggin));
          dispatch(loaded());
          dispatch(logLoginUserPassword());
        }

      })
      .catch(error => {
        dispatch(logError(error));
        if (error && error.code) {
          switch (error.code) {
            case 'auth/user-not-found':
              dispatch(warningMessage('error.user.not.found'));
              break;
            case 'auth/email-already-in-use':
              dispatch(warningMessage('error.address.already.use'));
              break;
            case 'auth/wrong-password':
              dispatch(warningMessage('error.wrong.password'));
              break;
            case 'auth/invalid-email':
              dispatch(errorMessage('error.address.is.invalid'));
              break;
            default:
              dispatch(errorMessage('error.generic'));
              break;
          }
        } else {
          dispatch(errorMessage('error.generic'));
        }
        dispatch(loaded());
      });
  };
};

export const logout = user => {
  return dispatch => {
    dispatch(loading());
    firebaseImpl.auth()
      .signOut()
      .finally(() => {
        dispatch(loggedOut());
        dispatch(loaded());
      });
  };
};

export const recoverPassword = email => {
  return dispatch => {
    dispatch(loading());
    firebaseImpl.auth()
      .sendPasswordResetEmail(email)
      .then(resp => {
        dispatch(
          infoMessage('screens.forgotPassword.modal.message'),
        );
        dispatch(loaded());
        dispatch(logRecoverPassword());
      })
      .catch(error => {
        dispatch(logError(error));
        if (error && error.code) {
          switch (error.code) {
            case 'auth/user-not-found':
              dispatch(warningMessage('error.user.not.found'));
              break;
            case 'auth/invalid-email':
              dispatch(errorMessage('error.address.is.invalid'));
              break;
            default:
              dispatch(errorMessage('error.generic'));
              break;
          }
        } else {
          dispatch(errorMessage('error.generic'));
        }
        dispatch(loaded());
      });
  };
};

export const signInWithGoogle = handleAgreement => {
  return async dispatch => {
    dispatch(loading());
    firebaseImpl.auth()
      .signInWithPopup(googleProvider)
      .then(async resp => {
        const user = {
          email: resp.user.email,
          name: resp.user.displayName,
          id: resp.user.uid,
          photoURL: resp.user.photoURL,
        };
        //console.log('Getting user from DB...');
        const userFromDb = await getUserService(user);
        //console.log('User successfully retrieved from DB:', userFromDb);
        if (userFromDb && !userFromDb.agreement) {
          // User must set agreement
          //console.log('User must set agreement');
          dispatch(loaded());
          handleAgreement && handleAgreement(user, false);
        } else if (userFromDb && userFromDb.privacyPolicyDate < lastPrivacyPolicyDate) {
          // User must update the agreement
          dispatch(loaded());
          //console.log('User must update the agreement');
          handleAgreement && handleAgreement(user, true);
        } else if (!userFromDb) {
          dispatch(loaded());
          //console.log('User is not created');
          handleAgreement && handleAgreement(user, true);
        } else {
          // User can login
          dispatch(loggedIn(user));
          dispatch(loaded());
          dispatch(logLoginGoogle());
        }
      })
      .catch(error => {
        dispatch(logError(error));
        if (error && error.code) {
          switch (error.code) {
            case 'auth/account-exists-with-different-credential':
              dispatch(
                warningMessage(
                  'error.account.exists.with.different.credential',
                ),
              );
              break;
            case 'auth/user-disabled':
              dispatch(errorMessage('error.user.disabled'));
              break;
            default:
              dispatch(errorMessage('error.generic'));
              break;
          }
        } else {
          dispatch(errorMessage('error.generic'));
        }
        dispatch(loaded());
      });
  };
};

export const signUpWithGoogle = () => {
  return async dispatch => {
    dispatch(loading());
    firebaseImpl.auth()
      .signInWithPopup(googleProvider)
      .then(resp => {
        const user = {
          email: resp.user.email,
          name: resp.user.displayName,
          id: resp.user.uid,
          photoURL: resp.user.photoURL,
        };
        const unsubscribe = firebaseImpl
          .auth()
          .onAuthStateChanged(userToCreate => {
            if (userToCreate) {
              createUserService(
                user,
                true,
                // Success
                null,
                // Error
                (error) => {
                  dispatch(logError(error));
                },
                // Always
                () => {
                  dispatch(loggedIn(user));
                  dispatch(loaded());
                  dispatch(logLoginGoogle());
                  unsubscribe();
                }
              );
            } else {
              dispatch(loggedIn(user));
              dispatch(loaded());
              dispatch(logLoginGoogle());
              unsubscribe();
            }
          });
      })
      .catch(error => {
        dispatch(logError(error));
        if (error && error.code) {
          switch (error.code) {
            case 'auth/account-exists-with-different-credential':
              dispatch(
                warningMessage(
                  'error.account.exists.with.different.credential',
                ),
              );
              break;
            case 'auth/user-disabled':
              dispatch(errorMessage('error.user.disabled'));
              break;
            default:
              dispatch(errorMessage('error.generic'));
              break;
          }
        } else {
          dispatch(errorMessage('error.generic'));
        }
        dispatch(loaded());
      });
  };
};

export const signInWithFacebook = handleAgreement => {
  return async dispatch => {
    dispatch(loading());
    firebaseImpl.auth()
      .signInWithPopup(facebookProvider)
      .then(async resp => {
        const user = {
          email: resp.user.email,
          name: resp.user.displayName,
          id: resp.user.uid,
          photoURL: resp.user.photoURL,
        };
        const userFromDb = await getUserService(user);
        if (userFromDb && !userFromDb.agreement) {
          // User must set agreement
          //console.log('User must set agreement');
          dispatch(loaded());
          handleAgreement && handleAgreement(user, false);
        } else if (userFromDb && userFromDb.privacyPolicyDate < lastPrivacyPolicyDate) {
          // User must update the agreement
          dispatch(loaded());
          //console.log('User must update the agreement');
          handleAgreement && handleAgreement(user, true);
        } else if (!userFromDb) {
          dispatch(loaded());
          //console.log('User is not created');
          handleAgreement && handleAgreement(user, true);
        } else {
          // User can login
          dispatch(loggedIn(user));
          dispatch(loaded());
          dispatch(logLoginFacebook());
        }
      })
      .catch(error => {
        dispatch(logError(error));
        if (error && error.code) {
          switch (error.code) {
            case 'auth/account-exists-with-different-credential':
              dispatch(
                warningMessage(
                  'error.account.exists.with.different.credential',
                ),
              );
              break;
            case 'auth/user-disabled':
              dispatch(errorMessage('error.user.disabled'));
              break;
            default:
              dispatch(errorMessage('error.generic'));
              break;
          }
        } else {
          dispatch(errorMessage('error.generic'));
        }
        dispatch(loaded());
      });
  };
};

export const signUpWithFacebook = () => {
  return async dispatch => {
    dispatch(loading());
    firebaseImpl.auth()
      .signInWithPopup(facebookProvider)
      .then(resp => {
        const user = {
          email: resp.user.email,
          name: resp.user.displayName,
          id: resp.user.uid,
          photoURL: resp.user.photoURL,
        };
        const unsubscribe = firebaseImpl
          .auth()
          .onAuthStateChanged(userToCreate => {
            if (userToCreate) {
              createUserService(
                user,
                true,
                // Success
                null,
                // Error
                (error) => {
                  dispatch(logError(error));
                },
                // Always
                () => {
                  dispatch(loggedIn(user));
                  dispatch(loaded());
                  dispatch(logLoginFacebook());
                  unsubscribe();
                }
              );
            } else {
              dispatch(loggedIn(user));
              dispatch(loaded());
              dispatch(logLoginFacebook());
              unsubscribe();
            }
          });

      })
      .catch(error => {
        dispatch(logError(error));
        if (error && error.code) {
          switch (error.code) {
            case 'auth/account-exists-with-different-credential':
              dispatch(
                warningMessage(
                  'error.account.exists.with.different.credential',
                ),
              );
              break;
            case 'auth/user-disabled':
              dispatch(errorMessage('error.user.disabled'));
              break;
            default:
              dispatch(errorMessage('error.generic'));
              break;
          }
        } else {
          dispatch(errorMessage('error.generic'));
        }
        dispatch(loaded());
      });
  };
};

export const signInWithApple = handleAgreement => {
  return async dispatch => {
    dispatch(loading());
    appleProvider.addScope('email');
    appleProvider.addScope('name');
    firebaseImpl.auth()
      .signInWithPopup(appleProvider)
      .then(async resp => {
        const email = resp.user.email;
        const name =
          resp.user.displayName || email.substring(0, email.indexOf('@'));
        const user = {
          email,
          name,
          id: resp.user.uid,
          photoURL: resp.user.photoURL,
        };
        //console.log('Getting user from DB...');
        const userFromDb = await getUserService(user);
        //console.log('User successfully retrieved from DB:', userFromDb);
        if (userFromDb && !userFromDb.agreement) {
          // User must set agreement
          //console.log('User must set agreement');
          dispatch(loaded());
          handleAgreement && handleAgreement(user, false);
        } else if (userFromDb && userFromDb.privacyPolicyDate < lastPrivacyPolicyDate) {
          // User must update the agreement
          dispatch(loaded());
          //console.log('User must update the agreement');
          handleAgreement && handleAgreement(user, true);
        } else if (!userFromDb) {
          dispatch(loaded());
          //console.log('User is not created');
          handleAgreement && handleAgreement(user, true);
        } else {
          // User can login
          dispatch(loggedIn(user));
          dispatch(loaded());
          dispatch(logLoginApple());
        }
      })
      .catch(error => {
        dispatch(logError(error));
        if (error && error.code) {
          switch (error.code) {
            case 'auth/account-exists-with-different-credential':
              dispatch(
                warningMessage(
                  'error.account.exists.with.different.credential',
                ),
              );
              break;
            case 'auth/user-disabled':
              dispatch(errorMessage('error.user.disabled'));
              break;
            default:
              dispatch(errorMessage('error.generic'));
              break;
          }
        } else {
          dispatch(errorMessage('error.generic'));
        }
        dispatch(loaded());
      });
  };
};

export const signUpWithApple = () => {
  return async dispatch => {
    dispatch(loading());
    appleProvider.addScope('email');
    appleProvider.addScope('name');
    firebaseImpl.auth()
      .signInWithPopup(appleProvider)
      .then(resp => {
        const email = resp.user.email;
        const name =
          resp.user.displayName || email.substring(0, email.indexOf('@'));
        const user = {
          email,
          name,
          id: resp.user.uid,
          photoURL: resp.user.photoURL,
        };
        const unsubscribe = firebaseImpl
          .auth()
          .onAuthStateChanged(userToCreate => {
            if (userToCreate) {
              createUserService(
                user,
                true,
                // Success
                null,
                // Error
                (error) => {
                  dispatch(logError(error));
                },
                // Always
                () => {
                  dispatch(loggedIn(user));
                  dispatch(loaded());
                  dispatch(logLoginApple());
                  unsubscribe();
                }
              );
            } else {
              dispatch(loggedIn(user));
              dispatch(loaded());
              dispatch(logLoginApple());
              unsubscribe();
            }
          });
      })
      .catch(error => {
        dispatch(logError(error));
        if (error && error.code) {
          switch (error.code) {
            case 'auth/account-exists-with-different-credential':
              dispatch(
                warningMessage(
                  'error.account.exists.with.different.credential',
                ),
              );
              break;
            case 'auth/user-disabled':
              dispatch(errorMessage('error.user.disabled'));
              break;
            default:
              dispatch(errorMessage('error.generic'));
              break;
          }
        } else {
          dispatch(errorMessage('error.generic'));
        }
        dispatch(loaded());
      });
  };
};