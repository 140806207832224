import React, {useContext, useEffect} from 'react';
import {LocalizationContext} from '../locales/Translate';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, List, ListItem, ListItemText, ListItemIcon, Hidden} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {useDispatch} from 'react-redux';
import {logCurrentScreen} from '../store/actions/analytics';
import PrivacyPolicyContent from './PrivacyPolicyContent';

const useStyles = makeStyles((theme) => ({
  title: {
    padding: theme.spacing(3),
  },
  backTitle: {
    paddingLeft: 0,
    paddingTop: 0,
  },
  listBack: {
    padding: theme.spacing(3),
    paddingBottom: 0,
  },
  container: {
    height: `calc(100vh - 65px)`,
    overflow: 'scroll',
    padding: 0,
  }
}));

export default props =>  {
  const classes = useStyles();
  const {locale} = useContext(LocalizationContext);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logCurrentScreen('PrivacyPolicy'));
  }, [dispatch]);

  return (
    <Grid container className={classes.container}>
      <Hidden mdUp implementation="css">
        <List className={classes.listBack}>
          <ListItem button className={classes.backTitle} onClick={props.handleBack}>
            <ListItemIcon>
              <ChevronLeft />
            </ListItemIcon>
            <ListItemText primary={locale.t('screens.settings.privacyPolicy')} />
          </ListItem>
        </List>
      </Hidden>
      <div className={classes.title}>
        <PrivacyPolicyContent />
      </div>
    </Grid>
  );
}