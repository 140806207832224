import React, {useContext} from 'react';
import {LocalizationContext} from '../locales/Translate';
import {Snackbar} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import {useSelector, useDispatch} from 'react-redux';
import {clearMessage} from '../store/actions/message';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default () => {
  const dispatch = useDispatch();
  const message = useSelector(state => state.message);
  const {locale} = useContext(LocalizationContext);

  const handleClose = () => {
    dispatch(clearMessage());
  }

  return (
    message.messageKey &&
     <Snackbar
      open={!!message.messageKey}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
      <Alert onClose={handleClose} severity={message.severity}>
        {message.messageKey && locale.t(message.messageKey)}
      </Alert>
    </Snackbar>
  );
}