import {loading, loaded} from './loading';
import {errorMessage} from './message';
import {
  createListService,
  deleteListService,
  updateListService,
  toggleItemService,
  createItemService,
  deleteItemService,
  updateItemService,
} from '../../services/shoppingListService';
import {logError} from './analytics';

export const createList = (listName, user) => {
  return dispatch => {
    dispatch(loading());
    createListService(
      listName,
      user,
      null,
      error => {
        // console.log(error);
        dispatch(logError(error));
        dispatch(errorMessage('error.generic'));
      },
      () => dispatch(loaded()),
    );
  };
};

export const deleteList = (id, user) => {
  return dispatch => {
    dispatch(loading());
    deleteListService(
      id,
      user,
      null,
      error => {
        // console.log(error);
        dispatch(logError(error));
        dispatch(errorMessage('error.generic'));
      },
      () => {
        dispatch(loaded());
      },
    );
  };
};

export const updateList = (list, user) => {
  return dispatch => {
    dispatch(loading());
    updateListService(
      list,
      user,
      null,
      error => {
        dispatch(logError(error));
        dispatch(errorMessage('error.generic'));
      },
      () => {
        dispatch(loaded());
      },
    );
  };
};

export const toggleItem = (listId, itemId, user) => {
  return dispatch => {
    dispatch(loading());
    toggleItemService(
      listId,
      itemId,
      user,
      null,
      error => {
        //console.log(error);
        dispatch(logError(error));
        dispatch(errorMessage('error.generic'));
      },
      () => {
        dispatch(loaded());
      },
    );
  }
};

export const createItem = (listId, item, user) => {
  return dispatch => {
    dispatch(loading());
    createItemService(
      listId,
      item,
      user,
      null,
      error => {
        //console.log(error);
        dispatch(logError(error));
        dispatch(errorMessage('error.generic'));
      },
      () => {
        dispatch(loaded());
      }
    );
  };
};

export const deleteItem = (listId, itemId, user) => {
  return dispatch => {
    dispatch(loading());
    deleteItemService(
      listId,
      itemId,
      user,
      null,
      error => {
        // console.log(error);
        dispatch(logError(error));
        dispatch(errorMessage('error.generic'));
      },
      () => {
        dispatch(loaded());
      },
    );
  };
};

export const updateItem = (listId, item, user) => {
  return dispatch => {
    dispatch(loading());
    updateItemService(
      listId,
      item,
      user,
      null,
      error => {
        // console.log(error);
        dispatch(logError(error));
        dispatch(errorMessage('error.generic'));
      },
      () => {
        dispatch(loaded());
      },
    );
  };
};
