import {firebaseImpl} from '../../config/Firebase';

const LOGIN_METHOD_USER_PASSWORD = 'user_password';
const LOGIN_METHOD_GOOGLE = 'google';
const LOGIN_METHOD_FACEBOOK = 'facebook';
const LOGIN_METHOD_APPLE = 'apple';
const RECOVER_PASSWORD = 'recover_password';
const LOGGED_ACCESS = 'logged_access';
const ANONYMOUS_ACCESS = 'anonymous_access';
const APP_LANGUAGE = 'app_language';
const APP_THEME = 'app_theme';
const EXCEPTION = 'exception';
const QUESTION = 'question';

export const logLoginUserPassword = () => {
  return dispatch => {
    firebaseImpl.analytics().logEvent('login', {method: LOGIN_METHOD_USER_PASSWORD});
  };
};

export const logLoginGoogle = () => {
  return dispatch => {
    firebaseImpl.analytics().logEvent('login', {method: LOGIN_METHOD_GOOGLE});
  };
};

export const logLoginFacebook = () => {
  return dispatch => {
    firebaseImpl.analytics().logEvent('login', {method: LOGIN_METHOD_FACEBOOK});
  };
};

export const logLoginApple = () => {
  return dispatch => {
    firebaseImpl.analytics().logEvent('login', {method: LOGIN_METHOD_APPLE});
  };
};

export const logSignUpUserPassword = () => {
  return dispatch => {
    firebaseImpl.analytics().logEvent('sign_up', {method: LOGIN_METHOD_USER_PASSWORD});
    firebaseImpl.analytics().logEvent('login', {method: LOGIN_METHOD_USER_PASSWORD});
  };
};

export const logRecoverPassword = userId => {
  return dispatch => {
    firebaseImpl.analytics().logEvent(RECOVER_PASSWORD, {userId});
  };
};

export const logOpen = (user, appLanguage, currentTheme) => {
  return dispatch => {
    firebaseImpl.analytics().logEvent('screen_view', {app_name: 'MyClubList_Web'});
    const isLogged = !!user.name;
    if (isLogged) {
      firebaseImpl.analytics().setUserId(user.id);
      firebaseImpl.analytics().logEvent(LOGGED_ACCESS, {id: user.id});
    } else {
      firebaseImpl.analytics().logEvent(ANONYMOUS_ACCESS, {});
    }
    firebaseImpl.analytics().logEvent(APP_LANGUAGE, {appLanguage});
    firebaseImpl.analytics().logEvent(APP_THEME, {theme: currentTheme});
  };
};

export const logCurrentScreen = screen => {
  return dispatch => {
    firebaseImpl.analytics().setCurrentScreen(screen, screen);
  };
};

export const logError = error => {
  const name = (error && error.name) || 'error';
  const message = (error && error.message) || 'unknown';
  return dispatch => {
    firebaseImpl.analytics().logEvent(EXCEPTION, {description: `${name}: ${message}`});
  };
};

export const logQuestion = (id, question) => {
  return dispatch => {
    firebaseImpl.analytics().logEvent(QUESTION, {questionId: id, question});
  };
};