import React, {useContext, useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useDispatch} from 'react-redux';
import {recoverPassword} from '../store/actions/user';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {LocalizationContext} from '../locales/Translate';
import {TextField} from '@material-ui/core';
import {logCurrentScreen} from '../store/actions/analytics';

const useStyles = makeStyles((theme) => ({
  modal: {

  },
}));

export default props =>  {
  const classes = useStyles();
  const {open, handleClose} = props;
  const {locale} = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const initialEmail = '';
  const [email, setEmail] = useState(initialEmail);
  const handleCoseModal = () => {
    handleClose();
    setEmail(initialEmail);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(recoverPassword(email));
    handleCoseModal();
  }

  useEffect(() => {
    dispatch(logCurrentScreen('ForgotPasswordModal'));
  }, [dispatch]);

  return (
      <Dialog
      className={classes.modal}
      open={open}
      onClose={handleCoseModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth="sm"
      >
      <form
        noValidate={false}
        onSubmit={handleSubmit} >
        <DialogTitle id="alert-dialog-title" data-testid="forgot-password-title">
          {locale.t('screens.forgotPassword.modal.title')}
        </DialogTitle>
        <DialogContent>
          <TextField
            data-testid="forgotPasswordEmail"
            required
            autoFocus
            margin="dense"
            id="forgotPasswordEmail"
            label={locale.t('screens.forgotPassword.modal.placeholder')}
            type="email"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCoseModal}
            data-testid="cancel-forgot-password">
            {locale.t('screens.forgotPassword.modal.button.cancel')}
          </Button>
          <Button
            data-testid="submit-forgot-password"
            type="submit"
            autoFocus>
            {locale.t('screens.forgotPassword.modal.button.recover')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}