import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/analytics';

const config = {
  apiKey: "AIzaSyBAt8a3Rf29d-jZeLV9EZDmovt8sa-tOQw",
  authDomain: "my-club-list.firebaseapp.com",
  databaseURL: "https://my-club-list.firebaseio.com",
  projectId: "my-club-list",
  storageBucket: "my-club-list.appspot.com",
  messagingSenderId: "558677347831",
  appId: "1:558677347831:web:750193393cc7cab3ea32f2",
  measurementId: "G-T13WSQRYL5"
};

export const firebaseImpl = firebase.initializeApp(config);
firebase.analytics();
export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const facebookProvider = new firebase.auth.FacebookAuthProvider();
export const appleProvider = new firebase.auth.OAuthProvider('apple.com');