import {THEME_CHANGED, THEME_RESTORED} from './actionTypes';

export const changeTheme = theme => ({
  type: THEME_CHANGED,
  payload: {theme},
});

export const restoreTheme = () => ({
  type: THEME_RESTORED,
});
