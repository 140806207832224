import React, {useState} from 'react';
import SignIn from './SignIn';
import SignUp from './SignUp';

export default () => {
  const [signIn, setSignIn] = useState(true);
  return (
    <>
    {
      signIn ?
        <SignIn swap={() => setSignIn(false)} />
      :
        <SignUp swap={() => setSignIn(true)} />
    }
    </>
  );
}