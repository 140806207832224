import pink from '@material-ui/core/colors/pink';
import green from '@material-ui/core/colors/green';
import orange from '@material-ui/core/colors/orange';
import grey from '@material-ui/core/colors/grey';

export const DEFAULT_THEME = 'lightTheme';
export const DARK_THEME = 'darkTheme';

export const THEMES = {
  lightTheme: {
    palette: {
      type: 'light',
      primary: {
        main: '#1a237e',
        '500': '#1a237e',
      },
    },
    overrides: {
      MuiLink: {
        root: {
          color: '#3f51b5',
        }
      }
    }
  },
  pinkTheme: {
    palette: {
      type: 'light',
      primary: pink,
    },
    overrides: {
      MuiLink: {
        root: {
          color: '#3f51b5',
        }
      }
    }
  },
  greenTheme: {
    palette: {
      type: 'light',
      primary: green,
    },
    overrides: {
      MuiLink: {
        root: {
          color: '#3f51b5',
        }
      }
    }
  },
  orangeTheme: {
    palette: {
      type: 'light',
      primary: orange,
    },
    overrides: {
      MuiLink: {
        root: {
          color: '#3f51b5',
        }
      }
    }
  },
  darkTheme: {
    palette: {
      type: 'dark',
      primary: {
        main: grey[800],
      },
    },
    overrides: {
      MuiInput: {
        underline: {
          '&:after': {
            borderBottomColor: 'rgba(255, 255, 255, 0.5)',
          },
        }
      },
      MuiFormLabel: {
        root: {
          '&.Mui-focused': {
            color: 'rgba(255, 255, 255, 0.5)',
          },
        }
      },
      MuiLink: {
        root: {
          color: 'rgba(255, 255, 255, 0.7)',
        }
      }
    }
  }
}