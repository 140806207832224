import React, {useContext} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useDispatch} from 'react-redux';
import {logout} from '../store/actions/user';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {LocalizationContext} from '../locales/Translate';

const useStyles = makeStyles((theme) => ({
  modal: {

  },
}));

export default props =>  {
  const classes = useStyles();
  const {locale} = useContext(LocalizationContext);
  const dispatch = useDispatch();
  return (
      <Dialog
      className={classes.modal}
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth="sm"
      >
      <DialogTitle id="alert-dialog-title">{locale.t('screens.signout.modal.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {locale.t('screens.signout.modal.message')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose}>
          {locale.t('screens.signout.button.cancel')}
        </Button>
        <Button
          onClick={() => {
            props.handleClose()
            dispatch(logout())
          }}
          autoFocus>
          {locale.t('screens.signout.button.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}