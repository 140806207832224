import {createStore, combineReducers, compose, applyMiddleware} from 'redux';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import languageReducer from './reducers/language';
import themeReducer from './reducers/theme';
import userReducer from './reducers/user';
import menuReducer from './reducers/menu';
import loadingReducer from './reducers/loading';
import messageReducer from './reducers/message';

const persistConfig = {
  key: 'myClubListWeb',
  storage,
  blacklist: ['loading', 'message'],
};

const reducers = combineReducers({
  language: languageReducer,
  theme: themeReducer,
  user: userReducer,
  menu: menuReducer,
  loading: loadingReducer,
  message: messageReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export default () =>
  createStore(persistedReducer, compose(applyMiddleware(thunk)));
