import React, {useContext, useEffect} from 'react';
import {LocalizationContext} from '../locales/Translate';
import {makeStyles} from '@material-ui/core/styles';
import {useSelector,useDispatch} from 'react-redux';
import {changeTheme, restoreTheme} from '../store/actions/theme';
import {Grid, Typography, List, ListItem, ListItemText, ListItemIcon, Hidden, Switch, Fab} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {DARK_THEME, THEMES} from '../themes';
import {logCurrentScreen} from '../store/actions/analytics';

const useStyles = makeStyles((theme) => ({
  title: {
    paddingBottom: theme.spacing(1),
  },
  backTitle: {
    paddingLeft: 0,
    paddingTop: 0,
  },
  listBack: {
    paddingTop: 0,
  },
  chooseTheme: {
    paddingTop: theme.spacing(2),
  },
  themes: {
    paddingTop: theme.spacing(4),
  },
  themeButton: {
    width: 40,
    height: 40,
    borderRadius: 20,
  },
  elementContainer: {
    padding: theme.spacing(3)
  },
}));

export default props =>  {
  const classes = useStyles();
  const {locale} = useContext(LocalizationContext);
  const currentTheme = useSelector(state => state.theme.currentTheme);
  const dispatch = useDispatch();

  const handleChange = () => {
    if (currentTheme === DARK_THEME) {
      dispatch(restoreTheme());
    } else {
      dispatch(changeTheme(DARK_THEME));
    }
  };

  useEffect(() => {
    dispatch(logCurrentScreen('Theme'));
  }, [dispatch]);

  return (
    <Grid container direction="column" className={classes.elementContainer}>
      <Hidden mdUp implementation="css">
        <List className={classes.listBack}>
          <ListItem button className={classes.backTitle} onClick={props.handleBack}>
            <ListItemIcon>
              <ChevronLeft />
            </ListItemIcon>
            <ListItemText primary={locale.t('screens.settings.theme')} />
          </ListItem>
        </List>
      </Hidden>
      <Grid item xs>
        <Grid
          container
          direction="row"
          alignItems="center"
          justify="space-between">
          <Grid item>
            <Typography>
              {locale.t('screens.theme.darkTheme')}
            </Typography>
          </Grid>
          <Grid item>
            <Switch
              checked={currentTheme === DARK_THEME}
              onChange={handleChange}
              color="secondary"
              name="darkCheck"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs className={classes.chooseTheme}>
        <Typography>
          {locale.t('screens.theme.chooseTheme')}
        </Typography>
      </Grid>
      <Grid item xs className={classes.themes}>
        <Grid
          container
          direction="row"
          alignItems="center"
          justify="space-between">
          {Object.entries(THEMES).map((theme, index) => {
            const themeName = theme[0];
            const themeObject = theme[1];
            if (themeName !== DARK_THEME) {
              return (
                <Fab
                  key={index}
                  className={classes.themeButton}
                  style={{backgroundColor: themeObject.palette.primary['500']}}
                  onClick={() => {
                    dispatch(changeTheme(themeName));
                  }}>
                    <Typography />
                </Fab>
              );
            } else {
              return null;
            }
          })}
        </Grid>
      </Grid>
    </Grid>
  );
}