import {MESSAGE_SETTED} from '../actions/actionTypes';

export const initialState = {
  messageKey: null,
  severity: null,
};

export default (state = initialState, action = null) => {
  if (action.type === MESSAGE_SETTED) {
    return {
      ...state,
      messageKey: action.payload.message.messageKey,
      severity: action.payload.message.severity || 'info',
    };
  } else {
    return state;
  }
};
