import {MESSAGE_SETTED} from './actionTypes';

export const errorMessage = messageKey =>
  setMessage({
    messageKey,
    severity: 'error',
});

export const warningMessage = messageKey =>
  setMessage({
    messageKey,
    severity: 'warning',
});

export const infoMessage = messageKey =>
  setMessage({
    messageKey,
    severity: 'info',
});

export const successMessage = messageKey =>
  setMessage({
    messageKey,
    severity: 'success',
});

export const clearMessage = () =>
  setMessage({
    messageKey: null,
    severity: null,
});

const setMessage = message => ({
  type: MESSAGE_SETTED,
  payload: {message},
});
