import React from 'react';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import Link from '@material-ui/core/Link';

export default props => {
  const { icon, primary, to, click } = props;

  const renderLink = React.useMemo(
    () => React.forwardRef(
      (itemProps, ref) =>
        <Link href={to} ref={ref} target="_blank" {...itemProps} color="inherit" />),
    [to],
  );

  return (
    <ListItem button component={to ? renderLink : null} onClick={click}>
      {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
      <ListItemText primary={primary} />
    </ListItem>
  );
}