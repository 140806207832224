import React, {useContext, useState} from 'react';
import {LocalizationContext} from '../locales/Translate';
import {makeStyles} from '@material-ui/core/styles';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Avatar,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import {
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import {drawerWidth} from '../util';
import Gravatar from 'react-gravatar';
import SignOutModal from './SignOutModal';
import {useSelector} from 'react-redux';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${theme.spacing(9)}px)`,
    },
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
    },
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  headerContainer: {
    paddingRight: 24, // keep right padding when drawer closed
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerElement: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    cursor: 'pointer',
    borderWidth: 3,
    borderRadius: 20,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  userMenu: {
    paddingLeft: 30,
    paddingRight: 30,
  }
}));

export default props => {
  const classes = useStyles();
  const {locale} = useContext(LocalizationContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [signOut, setSignOut] = useState(false);
  const user = useSelector(state => state.user);
  const menuOpen = useSelector(state => state.menu.menuOpen);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="absolute" className={clsx(classes.appBar, menuOpen && classes.appBarShift)}>
      <SignOutModal open={signOut} handleClose={() => setSignOut(false)}/>
      <Toolbar className={classes.headerContainer}>
        <div className={classes.headerElement}>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
            onClick={props.toggleMobileDrawer}
          >
            <MenuIcon />
          </IconButton>
          <Switch>
            <Route exact path="/">
              <Typography data-testid="app-title" variant="h6" className={classes.title}>
                {locale.t('header.home')}
              </Typography>
            </Route>
            <Route path="/settings">
              <Typography data-testid="app-title" variant="h6" className={classes.title}>
                {locale.t('header.settings')}
              </Typography>
            </Route>
            <Route path="/help">
              <Typography data-testid="app-title" variant="h6" className={classes.title}>
                {locale.t('header.help')}
              </Typography>
            </Route>
            <Route path="*">
              <Redirect to="/" />
            </Route>
          </Switch>
        </div>
        <div className={classes.headerElement}>
          {user && user.photoURL ? (
            <Avatar
              data-testid="avatar"
              alt={user.name}
              src={user.photoURL}
              className={classes.avatar}
              onClick={handleClick} />
            ) : (
            <Gravatar
              data-testid="gravatar"
              className={classes.avatar}
              email={user.email}
              size={40}
              onClick={handleClick}
              aria-controls="user-menu"
              aria-haspopup="true"
              default="mp"
            />
          )}
          <Menu
            id="user-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              className={classes.userMenu}
              disabled={true}
            >
              {user.name}
            </MenuItem>
            <MenuItem
              className={classes.userMenu}
              disabled={true}
            >
              {user.email}
            </MenuItem>
            <MenuItem
              className={classes.userMenu}
              onClick={() => setSignOut(true)}
            >
              {locale.t('menu.signout')}
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
}