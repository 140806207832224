import React, {useState, useContext} from 'react';
import {Divider, ListItem, ListItemText, Checkbox, Tooltip, Typography} from '@material-ui/core';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {useDispatch, useSelector} from 'react-redux';
import {LocalizationContext} from '../locales/Translate';
import {deleteItem} from '../store/actions/shoppingList';
import {toggleItem} from '../store/actions/shoppingList';
import clsx from 'clsx';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import {green} from '@material-ui/core/colors';
import DeleteModal from './DeleteModal';
import ItemModal from './ItemModal';

const useStyles = makeStyles((theme) => ({
  controlIcons: {
    paddingRight: 10,
    paddingLeft: 10,
  },
  row: {
    minHeight: 65,
  },
  textChecked: {
    textDecoration: 'line-through',
  },
  textQuantity: {
    marginRight: theme.spacing(1),
  }
}));

const GreenCheckbox = withStyles({
  root: {
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);


export default props =>  {
  const classes = useStyles();
  const {item, listId} = props;
  const [showControls, setShowControls] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const {locale} = useContext(LocalizationContext);

  const handleCheckItem = () => {
    dispatch(toggleItem(listId, item.id, user))
  }

  const [showItemModal, setShowItemModal] = useState(false);
  const handleItemModalClose = () => {
    setShowItemModal(false)
  }
  const openItemModal = () => {
    setShowItemModal(true)
  }

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleDeleteModalClose = () => {
    setShowDeleteModal(false)
  }
  const openDeleteModal = () => {
    setShowDeleteModal(true)
  }

  const handleDelete = () => {
    dispatch(deleteItem(listId, item.id, user));
  }

  return (
    <div>
      <ItemModal
        item={item}
        listId={listId}
        handleClose={handleItemModalClose}
        open={showItemModal}/>
      <DeleteModal
        name={item.name}
        handleClose={handleDeleteModalClose}
        open={showDeleteModal}
        handleDelete={handleDelete}/>
      <ListItem
        className={classes.row}
        button
        onClick={(e) => {
          e.stopPropagation();
        }}
        onMouseEnter={() => setShowControls(true)}
        onMouseLeave={() => setShowControls(false)} >
        <GreenCheckbox
          color="default"
          checked={item.checked}
          onChange={e => {
            e.stopPropagation();
            handleCheckItem();
          }}
        />
        <ListItemText
          primary={item.name}
          className={clsx(item.checked && classes.textChecked)}/>
        <Typography
          className={clsx(classes.textQuantity, item.checked && classes.textChecked)}>
         {item.quantity}
        </Typography>
        {
          (showControls || item.checked) && (
            <>
              <IconButton
                className={classes.controlIcons}
                onClick={e => {
                  e.stopPropagation();
                  openItemModal();
                }}>
                <Tooltip
                  title={locale.t('screens.itemList.tooltip.edit')}
                  aria-label={locale.t('screens.itemList.tooltip.edit')}
                  arrow>
                  <EditIcon color="action" />
                </Tooltip>
              </IconButton>
              <IconButton
                className={classes.controlIcons}
                onClick={e => {
                  e.stopPropagation();
                  openDeleteModal();
                }}>
                <Tooltip
                  title={locale.t('screens.itemList.tooltip.delete')}
                  aria-label={locale.t('screens.itemList.tooltip.delete')}
                  arrow>
                  <DeleteIcon color="action" />
                </Tooltip>
              </IconButton>
            </>
          )
        }
      </ListItem>
      <Divider />
    </div>
  );
}
