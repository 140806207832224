import React, {useContext, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {LocalizationContext} from '../locales/Translate';
import {useDispatch} from 'react-redux';
import {logCurrentScreen} from '../store/actions/analytics';
import { Typography, Link } from '@material-ui/core';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import PollIcon from '@material-ui/icons/Poll';
import StorageIcon from '@material-ui/icons/Storage';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

const useStyles = makeStyles((theme) => ({
  modal: {},
  subtitle: {
    marginBottom: theme.spacing(2),
  },
  sectionTitle: {
    marginBottom: theme.spacing(2),
  },
  itemTitleContainer: {
    display: "flex",
    alignItems: "center",
  },
  itemIcon: {
    marginRight: theme.spacing(1),
  },
  itemSubtitle: {
    fontWeight: "bold",
  },
  itemDesc: {
    marginBottom: theme.spacing(2),
  },
}));

export default props =>  {
  const {handleClose, handleAccept, open} = props;
  const classes = useStyles();
  const {locale} = useContext(LocalizationContext);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logCurrentScreen('PrivacyPolicyModal'));
  }, [dispatch]);

  return (
      <Dialog
      className={classes.modal}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth="lg"
      scroll="paper"
      >
      <DialogTitle id="alert-dialog-title">
        {locale.t('privacy.policy.title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography component="p" className={classes.subtitle}>
            {locale.t('privacy.policy.subtitle')}
          </Typography>
          <Typography component="h2" variant="h6" className={classes.sectionTitle}>
             {locale.t('privacy.policy.section.title')}
          </Typography>

          <div className={classes.itemTitleContainer}>
            <PeopleAltIcon className={classes.itemIcon} />
            <Typography component="h2" variant="h6">
              {locale.t('privacy.policy.access.title')}
            </Typography>
          </div>
          <Typography component="p" className={classes.itemSubtitle}>
            {locale.t('privacy.policy.access.subtitle')}
          </Typography>
          <Typography component="p" className={classes.itemDesc}>
            {locale.t('privacy.policy.access.desc')}
          </Typography>

          <div className={classes.itemTitleContainer}>
            <InsertDriveFileIcon className={classes.itemIcon} />
            <Typography component="h2" variant="h6">
              {locale.t('privacy.policy.displaying.title')}
            </Typography>
          </div>
          <Typography component="p" className={classes.itemSubtitle}>
            {locale.t('privacy.policy.displaying.subtitle')}
          </Typography>
          <Typography component="p" className={classes.itemDesc}>
            {locale.t('privacy.policy.displaying.desc')}
          </Typography>

          <div className={classes.itemTitleContainer}>
            <RecentActorsIcon className={classes.itemIcon} />
            <Typography component="h2" variant="h6">
              {locale.t('privacy.policy.managing.title')}
            </Typography>
          </div>
          <Typography component="p" className={classes.itemSubtitle}>
            {locale.t('privacy.policy.managing.subtitle')}
          </Typography>
          <Typography component="p" className={classes.itemDesc}>
            {locale.t('privacy.policy.managing.desc')}
          </Typography>

          <div className={classes.itemTitleContainer}>
            <PollIcon className={classes.itemIcon} />
            <Typography component="h2" variant="h6">
              {locale.t('privacy.policy.analytics.title')}
            </Typography>
          </div>
          <Typography component="p" className={classes.itemSubtitle}>
            {locale.t('privacy.policy.analytics.subtitle')}
          </Typography>
          <Typography component="p" className={classes.itemDesc}>
            {locale.t('privacy.policy.analytics.desc')}
          </Typography>

          <div className={classes.itemTitleContainer}>
            <StorageIcon className={classes.itemIcon} />
            <Typography component="h2" variant="h6">
              {locale.t('privacy.policy.hosting.title')}
            </Typography>
          </div>
          <Typography component="p" className={classes.itemSubtitle}>
            {locale.t('privacy.policy.hosting.subtitle')}
          </Typography>
          <Typography component="p" className={classes.itemDesc}>
            {locale.t('privacy.policy.hosting.desc')}
          </Typography>

          <div className={classes.itemTitleContainer}>
            <VerifiedUserIcon className={classes.itemIcon} />
            <Typography component="h2" variant="h6">
              {locale.t('privacy.policy.registration.title')}
            </Typography>
          </div>
          <Typography component="p" className={classes.itemSubtitle}>
            {locale.t('privacy.policy.registration.subtitle')}
          </Typography>
          <Typography component="p" className={classes.itemDesc}>
            {locale.t('privacy.policy.registration.desc')}
          </Typography>

          <Typography component="h2" variant="h6" className={classes.sectionTitle}>
             {locale.t('privacy.policy.contact.title')}
          </Typography>
          <Typography component="p" className={classes.itemSubtitle}>
            {locale.t('privacy.policy.contact.subtitle')}
          </Typography>
          <Typography component="p">
            {locale.t('privacy.policy.contact.desc')}
          </Typography>
          <Typography component="p" className={classes.itemDesc}>
            <b>{locale.t('privacy.policy.contact.email.label')}</b> {locale.t('privacy.policy.contact.email.desc')}
          </Typography>
          <Typography component="p">
            {locale.t('privacy.policy.full.desc')}
          </Typography>
          <Typography component="p" className={classes.itemDesc}>
            <b>{locale.t('privacy.policy.full.label')} </b>
            <Link href={locale.t('privacy.policy.full.link')} target="_blank" rel="noreferrer">
              {locale.t('privacy.policy.full.link')}
            </Link>
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          {locale.t('screens.privacyPolicy.modal.button.disagree')}
        </Button>
        <Button
          onClick={() => {
            handleAccept();
            handleClose();
          }}
          color="primary"
          variant="contained">
          {locale.t('screens.privacyPolicy.modal.button.agree')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}