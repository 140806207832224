import {THEME_CHANGED, THEME_RESTORED} from '../actions/actionTypes';
import {DEFAULT_THEME, THEMES} from '../../themes';

export const initialState = {
  lastTheme: '',
  currentTheme: DEFAULT_THEME,
};

export default (state = initialState, action = null) => {
  if (action.type === THEME_CHANGED) {
    let newTheme = state.currentTheme;
    if (action && action.payload && THEMES[action.payload.theme]) {
      newTheme = action.payload.theme;
    }
    return {
      ...state,
      lastTheme: state.currentTheme,
      currentTheme: newTheme,
    };
  } else if (action.type === THEME_RESTORED) {
    let newTheme = state.lastTheme;
    if (!newTheme) {
      newTheme = DEFAULT_THEME;
    }
    return {
      ...state,
      lastTheme: state.currentTheme,
      currentTheme: newTheme,
    };
  } else {
    return state;
  }
};
