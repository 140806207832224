import {USER_LOGGED_IN, USER_LOGGED_OUT} from '../actions/actionTypes';
import {generateCurrentUnixTimestamp} from '../../util/helperFunctions';

export const initialState = {
  id: null,
  name: null,
  email: null,
  token: null,
  photoURL: null,
  lastLogin: null,
};

export default (state = initialState, action = null) => {
  switch (action.type) {
    case USER_LOGGED_IN:
      return {
        ...state,
        name: action.payload.user.name,
        email: action.payload.user.email,
        token: action.payload.user.token,
        id: action.payload.user.id,
        photoURL: action.payload.user.photoURL,
        lastLogin: generateCurrentUnixTimestamp(),
      };
    case USER_LOGGED_OUT:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
